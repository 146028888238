import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { PageableTable, RouterNavLink } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useDocumentTitle,
  useLocalStorageSettings,
} from 'hooks';
import {
  exportCompaniesList,
  fetchClients,
  fetchCompanyIndustries,
  fetchCompanyLeaders,
} from 'lib/Api/Clients';
import { stringAvatar } from 'lib/Helper/Avatars';
import {
  PagedClients,
  companyIndustries,
  companyLeaders,
} from 'lib/Model/Client';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  resolveMergedAliasesToPlatformNames,
  resolvePlatformName,
} from './SuggestedMatches';
import { brandList } from 'views/NPS/NpsDialog';
import { getUserData } from 'lib/Helper/Auth';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiCardHeader-action': {
        marginRight: 0,
        marginTop: theme.spacing(2),
        alignSelf: 'stretch',
      },
    },
  },
  headerActions: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
  cardTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  cardSubheader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  companyButton: {
    paddingLeft: 0,
  },
  companyCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  smallAvatar: {
    marginRight: theme.spacing(0.5),
    width: 22,
    height: 22,
    fontSize: 12,
  },
  accountManager: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
}));

export const ClientList: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash, search } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );
  const history = useHistory();

  useDocumentTitle('Clients');

  const searchParams = new URLSearchParams(search);
  const initialSearch = searchParams.get('search');
  const [textSearch, setTextSearch] = React.useState<string | undefined>(
    initialSearch || undefined,
  );

  const debouncedTextSearch = useDebounce(textSearch, 600);
  const [brand, setBrand] = React.useState<string[]>([]);
  const [leader, setLeader] = React.useState<string[]>([]);
  const [churn, setChurn] = React.useState<string[]>([]);
  const [headcount, setHeadcount] = React.useState<string[]>([]);
  const [industry, setInudstry] = React.useState<string[]>([]);
  const [hasOpenDeal, setHasOpenDeal] = React.useState<string | undefined>();

  const [industriesLoading, setIndustriesLoading] = React.useState(false);
  const [industriesData, setIndustriesData] = React.useState<
    companyIndustries[] | undefined
  >();

  const [leadersLoading, setLeadersLoading] = React.useState(false);
  const [leadersData, setLeadersData] = React.useState<
    companyLeaders[] | undefined
  >();

  const [exporting, setExporting] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [data, setData] = React.useState<PagedClients | undefined>();

  const abortControllerRef = React.useRef<AbortController | null>(null);
  const lastRequestTimeRef = React.useRef<number>(0);

  const fetchData = React.useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const requestTime = Date.now();
    lastRequestTimeRef.current = requestTime;

    setLoading(true);
    fetchClients(
      pageNumber + 1,
      tableSettings.rowsPerPage,
      debouncedTextSearch,
      brand,
      churn,
      leader,
      headcount,
      industry,
      hasOpenDeal,
      abortControllerRef.current.signal,
    )
      .then((d) => {
        if (requestTime === lastRequestTimeRef.current) {
          setLoading(false);
          setData(d);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    debouncedTextSearch,
    brand,
    churn,
    leader,
    headcount,
    industry,
    hasOpenDeal,
  ]);

  const exportData = React.useCallback(async () => {
    setExporting(true);
    setSnackbarOpen(true);
    await exportCompaniesList(
      debouncedTextSearch,
      brand,
      churn,
      leader,
      headcount,
      industry,
      hasOpenDeal,
    );
    setExporting(false);
  }, [
    debouncedTextSearch,
    brand,
    churn,
    leader,
    headcount,
    industry,
    hasOpenDeal,
  ]);

  const fetchIndustries = React.useCallback(() => {
    setIndustriesLoading(true);
    fetchCompanyIndustries().then((d) => {
      setIndustriesLoading(false);
      setIndustriesData(d);
    });
  }, [setIndustriesLoading, setIndustriesData]);

  const fetchLeaders = React.useCallback(() => {
    setLeadersLoading(true);
    fetchCompanyLeaders().then((d) => {
      setLeadersLoading(false);
      setLeadersData(d);
    });
  }, [setLeadersLoading, setLeadersData]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    fetchIndustries();
    fetchLeaders();
  }, [fetchIndustries, fetchLeaders]);

  React.useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const industryOptions = industriesData ?? [];

  const leaderOptions = [
    { company_leader: 'No CAM' },
    { company_leader: 'Expedition42 Administrator' },
    ...(leadersData ?? []),
  ];

  const columns: PageableTableHeader[] = [
    {
      key: 'id',
      label: '',
      sortable: false,
      props: {
        style: {
          width: 50,
        },
      },
    },
    {
      key: 'company',
      label: 'Company',
      sortable: false,
    },
    {
      key: 'cam',
      label: 'Client Account Manager',
      sortable: false,
    },
    {
      key: 'industry',
      label: 'Industry',
      sortable: false,
    },
    {
      key: 'headcount',
      label: 'Headcount',
      sortable: false,
    },
  ];

  const user = getUserData();
  if (!user) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          subheader: classes.cardSubheader,
        }}
        title="Expedition42 Clients"
        subheader="Browse through a list of client profiles. If the company has many profile merges, you will find more in-depth information on that client."
        action={
          <div className={classes.headerActions}>
            <Button
              color="secondary"
              href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.mqyf0y3flxiq"
              target="_blank"
            >
              Help
            </Button>
            <Button color="primary" href="/dashboards#commercial">
              Commercial Dashboard
            </Button>
            {user?.permissions?.includes('client_export') && (
              <Button
                color="secondary"
                onClick={exportData}
                disabled={exporting}
              >
                Export Data
                {exporting && (
                  <Box ml={1}>
                    <CircularProgress size={24} />
                  </Box>
                )}
              </Button>
            )}
          </div>
        }
      />
      <CardContent style={{ paddingTop: 0, paddingBottom: 8 }}>
        <Alert style={{ marginBottom: 8 }} severity="info">
          <Typography variant="body1">
            Seeing too many results for the same company? Speak to a CAM to
            merge the profiles.&nbsp;
            <a
              href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.bxfnpufkkzp8"
              target="_blank"
              rel="noreferrer"
            >
              What does this mean?
            </a>
          </Typography>
        </Alert>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={textSearch}
              variant="outlined"
              autoFocus
              onChange={(e) =>
                setTextSearch(e.currentTarget.value ?? undefined)
              }
              fullWidth
              placeholder="Type here to search for a company"
            />
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                multiple
                options={brandList}
                getOptionLabel={(b) => b.label}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Service Type"
                    placeholder={brand.length > 0 ? '' : 'All Brands'}
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={brandList.filter((b) =>
                  brand.includes(b.brand_identifier),
                )}
                onChange={(e, newValue) => {
                  setBrand(newValue.map((b) => b.brand_identifier));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                multiple
                options={[
                  'Active',
                  'Ok',
                  'Warning',
                  'Alert',
                  'Lost',
                  'Trial',
                  'CSP Client',
                ]}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Client Status"
                    placeholder={churn.length > 0 ? '' : 'All Statuses'}
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={churn}
                onChange={(e, newValue) => {
                  setChurn(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                multiple
                loading={leadersLoading}
                getOptionLabel={(o) => `${o.company_leader}`}
                options={leaderOptions}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="CAM"
                    placeholder={leader.length > 0 ? '' : 'All CAMs'}
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={leaderOptions.filter((r) =>
                  leader.includes(r.company_leader),
                )}
                onChange={(e, newValue) => {
                  setLeader(newValue.map((v) => v.company_leader));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                multiple
                options={[
                  '0-9 employees',
                  '10-49 employees',
                  '50-249 employees',
                  '250+ employees',
                ]}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Headcount"
                    placeholder={headcount.length > 0 ? '' : 'All Sizes'}
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={headcount}
                onChange={(e, newValue) => {
                  setHeadcount(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                multiple
                loading={industriesLoading}
                getOptionLabel={(o) => `${o.industry}`}
                options={industryOptions}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Industry"
                    placeholder={industry.length > 0 ? '' : 'All Industries'}
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={industryOptions.filter((r) =>
                  industry.includes(r.industry),
                )}
                onChange={(e, newValue) => {
                  setInudstry(newValue.map((v) => v.industry));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Autocomplete
                options={['Yes', 'No']}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    placeholder={
                      hasOpenDeal && hasOpenDeal.length > 0 ? '' : 'All Clients'
                    }
                    label="Clients with open deals"
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={hasOpenDeal}
                onChange={(e, newValue) => {
                  setHasOpenDeal(newValue !== null ? newValue : undefined);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>

      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d) => ({
            key: d.id,
            cells: [
              {
                key: 'id',
                display: d.company_title && (
                  <Avatar
                    onClick={() => {
                      history.push(`/clients/${d.id}`);
                    }}
                    variant="rounded"
                    {...stringAvatar(d.company_title)}
                  />
                ),
              },
              {
                key: 'company',
                display: (
                  <div className={classes.companyCell}>
                    <Button
                      className={classes.companyButton}
                      component={RouterNavLink}
                      variant="text"
                      to={`/clients/${d.id}`}
                      color="primary"
                    >
                      {d.company_title}
                    </Button>
                    <Typography variant="caption">
                      Sources:&nbsp;
                      {resolvePlatformName(d.id)}
                      {(() => {
                        const initialAliases =
                          resolveMergedAliasesToPlatformNames(
                            d.merged_aliases ?? null,
                          );

                        const filteredAliases = initialAliases.filter(
                          (alias) =>
                            alias !== 'KeepMePosted' &&
                            alias !== 'Alfred Jobs' &&
                            alias !== 'Zendesk Support',
                        );

                        const xeroAliases = [
                          'Xero Talexio',
                          'Xero Konnekt',
                          'Xero ITV',
                        ];
                        if (
                          filteredAliases.some((alias) =>
                            xeroAliases.includes(alias),
                          )
                        ) {
                          const concatenatedNames = filteredAliases
                            .filter((alias) => !xeroAliases.includes(alias))
                            .concat('Xero');
                          return (
                            ', ' + [...new Set(concatenatedNames)].join(', ')
                          );
                        }

                        const uniqueFilteredAliases = [
                          ...new Set(filteredAliases),
                        ];
                        return uniqueFilteredAliases.length > 0
                          ? ', ' + uniqueFilteredAliases.join(', ')
                          : '';
                      })()}
                    </Typography>
                  </div>
                ),
              },
              {
                key: 'cam',
                display: d.zendesk_leader ? (
                  <div className={classes.accountManager}>
                    <Avatar
                      className={classes.smallAvatar}
                      {...stringAvatar(d.zendesk_leader)}
                    />
                    <Typography variant="body1">{d.zendesk_leader}</Typography>
                  </div>
                ) : null,
              },

              {
                key: 'industry',
                display: d.industry,
              },
              {
                key: 'headcount',
                display: d.headcount,
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      ></Snackbar>
    </Card>
  );
};
