import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  IconButton,
  CircularProgress,
  Paper,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { fetchCompanyQuotes, fetchQuoteDetails } from 'lib/Api/QuoteGenerator';
import { PagedQuoteHistory, QuoteDetailItem } from 'lib/Model/QuoteGenerator';
import { getTableSettingsStorageKey, useLocalStorageSettings } from 'hooks';
import QuoteDetailsDialog from '../../QuoteGenerator/components/QuoteDetailsDialog';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    minWidth: '600px',
    minHeight: '400px',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
  tableContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 'calc(100% - 20px)',
    overflow: 'auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  emptyState: {
    textAlign: 'center',
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  dialogPaper: {
    maxWidth: '800px',
    width: '100%',
    maxHeight: '80vh',
  },
}));

interface ProductStackQuotesProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
}

const ProductStackQuotes: React.FC<ProductStackQuotesProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [quoteHistory, setQuoteHistory] = useState<
    PagedQuoteHistory | undefined
  >();
  const [pageNumber, setPageNumber] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [tableSettings, setTableSettings] = useLocalStorageSettings(
    getTableSettingsStorageKey('companyQuotes', companyId),
    { rowsPerPage: 10 },
  );

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState<number | null>(null);
  const [quoteDetails, setQuoteDetails] = useState<QuoteDetailItem[]>([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsError, setDetailsError] = useState<string | null>(null);

  const fetchQuotes = useCallback(async () => {
    if (!companyId || !open) return;

    setLoading(true);
    setError(null);
    try {
      const data = await fetchCompanyQuotes(
        companyId,
        pageNumber + 1,
        tableSettings.rowsPerPage,
      );
      setQuoteHistory(data);
    } finally {
      setLoading(false);
    }
  }, [companyId, open, pageNumber, tableSettings.rowsPerPage]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  const handleViewDetails = async (quoteId: number) => {
    setSelectedQuoteId(quoteId);
    setDetailsDialogOpen(true);
    setDetailsLoading(true);
    setDetailsError(null);

    try {
      const data = await fetchQuoteDetails(quoteId);
      setQuoteDetails(data);
    } catch (err) {
      setDetailsError(
        `Failed to load proposal details: ${
          err instanceof Error ? err.message : 'Unknown error'
        }`,
      );
    } finally {
      setDetailsLoading(false);
    }
  };

  const historyColumns: PageableTableHeader[] = [
    {
      key: 'date',
      label: 'Created On',
      sortable: false,
    },
    {
      key: 'company_name',
      label: 'Company Name',
      sortable: false,
    },
    {
      key: 'items',
      label: 'Items',
      sortable: false,
    },
    {
      key: 'created_by',
      label: 'Created By',
      sortable: false,
    },
    {
      key: 'details',
      label: 'Details',
      sortable: false,
    },
    {
      key: 'Link',
      label: 'Link',
      sortable: false,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="quotes-dialog-title"
        maxWidth="md"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="quotes-dialog-title">
          <Typography variant="h6">Proposals for this company</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : error ? (
            <Typography variant="body1" className={classes.errorMessage}>
              {error}
            </Typography>
          ) : quoteHistory?.data && quoteHistory.data.length > 0 ? (
            <div className={classes.tableContainer}>
              <Paper>
                <PageableTable
                  tableSettings={tableSettings}
                  setTableSettings={setTableSettings}
                  showSkeletonLoading
                  hideColumns={false}
                  rows={
                    quoteHistory.data.map((quote, index) => ({
                      key: index.toString(),
                      cells: [
                        {
                          key: 'date',
                          display: quote.created_on && (
                            <DateDisplay date={quote.created_on} />
                          ),
                        },
                        {
                          key: 'company_name',
                          display: quote.company_name,
                        },
                        {
                          key: 'items',
                          display: quote.items
                            .split(',')
                            .map((item) =>
                              item
                                .trim()
                                .replace(/_/g, ' ')
                                .replace(/\b(\w)/g, (m) => m.toUpperCase())
                                .replace(/\bHr\b/g, 'HR')
                                .replace(/\bCsp\b/g, 'CSP')
                                .replace(/\bRfid\b/g, 'RFID')
                                .replace(/\bEm\b/g, 'EM'),
                            )
                            .join(', '),
                        },
                        {
                          key: 'created_by',
                          display: quote.created_by,
                        },
                        {
                          key: 'details',
                          display: (
                            <IconButton
                              onClick={() => handleViewDetails(quote.quote_id)}
                              size="small"
                              title="View Quote Details"
                            >
                              <VisibilityIcon />
                            </IconButton>
                          ),
                        },
                        {
                          key: 'Link',
                          display: quote.quote_slide_id ? (
                            <Link
                              href={`https://docs.google.com/presentation/d/${quote.quote_slide_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              color="primary"
                            >
                              View
                            </Link>
                          ) : (
                            '-'
                          ),
                        },
                      ],
                    })) || []
                  }
                  loading={loading}
                  pageNumber={pageNumber}
                  rowCount={quoteHistory?.total_count ?? 0}
                  columns={historyColumns}
                  onChangePage={setPageNumber}
                />
              </Paper>
            </div>
          ) : (
            <div className={classes.emptyState}>
              <Typography variant="body1">
                No proposals found for this company.
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {selectedQuoteId && (
        <QuoteDetailsDialog
          open={detailsDialogOpen}
          quoteDetails={quoteDetails}
          loading={detailsLoading}
          error={detailsError}
          onClose={() => setDetailsDialogOpen(false)}
          quoteId={selectedQuoteId}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default ProductStackQuotes;
