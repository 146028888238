import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { PagedNews } from 'lib/Model/News';

export function fetchNews(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<PagedNews> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/news`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data,
      };
    });
}
