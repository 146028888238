import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DriveFolder, DriveCompany } from 'lib/Model/Drive';
import { fetchDriveFolders } from 'lib/Api/Drive';
import { createTerms } from 'lib/Api/TermsGenerator';
import { useDebounce } from 'hooks';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '500px',
  },
  formControl: {
    margin: theme.spacing(1, 0, 2),
    width: '100%',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  folderSearchBox: {
    marginTop: theme.spacing(2),
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  successLink: {
    marginTop: theme.spacing(2),
  },
  folderOptions: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
  radioGroup: {
    flexDirection: 'column',
  },
}));

interface TermsGenerationDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company: DriveCompany | null;
  companyName: string;
  registrationNumber: string;
  registrationType: 'registration' | 'vat' | 'other';
  address: string;
  signatory: string;
  dateOfIssue: Date;
  expiryDate: Date | null;
  brand: 'SIM' | 'Konnekt';
  companyId: string;
  termsType: 'Standard' | 'Exclusive';
  folderDestination: 'working';
}

export const TermsGenerationDialog: React.FC<TermsGenerationDialogProps> = ({
  open,
  onClose,
  onSuccess,
  company,
  companyName,
  registrationNumber,
  registrationType,
  address,
  signatory,
  dateOfIssue,
  expiryDate,
  brand,
  termsType,
  companyId,
  folderDestination,
}) => {
  const classes = useStyles();
  const [generationMethod, setGenerationMethod] = useState<
    'drive' | 'download'
  >('download');
  const [loading, setLoading] = useState(false);
  const [folderSearchTerm, setFolderSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(folderSearchTerm, 400);
  const [folderSuggestions, setFolderSuggestions] = useState<DriveFolder[]>([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<DriveFolder | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setGenerationMethod(company?.parent_folder_id ? 'drive' : 'download');
      setError(null);
      setSuccess(false);
      setDownloadLink(null);
      setDocumentId(null);
      setSelectedFolder(null);
      setFolderSearchTerm('');
    }
  }, [open, company]);

  useEffect(() => {
    const fetchFolders = async () => {
      if (debouncedSearchTerm.trim().length > 1) {
        setLoadingFolders(true);
        try {
          const response = await fetchDriveFolders(debouncedSearchTerm);
          setFolderSuggestions(response.data);
        } catch (error) {
          setFolderSuggestions([]);
        } finally {
          setLoadingFolders(false);
        }
      } else {
        setFolderSuggestions([]);
      }
    };

    fetchFolders();
  }, [debouncedSearchTerm]);

  const handleGenerate = async () => {
    setLoading(true);
    setError(null);

    try {
      let targetFolderId: string | undefined = undefined;

      if (generationMethod === 'drive') {
        if (company?.parent_folder_id) {
          if (company.working_agreements_folder_id) {
            targetFolderId = company.working_agreements_folder_id;
          } else {
            targetFolderId = company.parent_folder_id;
          }
        } else if (selectedFolder) {
          targetFolderId = selectedFolder.id;
        }
      }

      const formattedDate = dateOfIssue.toISOString().split('T')[0];
      const formattedExpiryDate = expiryDate
        ? expiryDate.toISOString().split('T')[0]
        : null;

      const request = {
        service: brand,
        companyName: companyName,
        documentSubType: brand === 'Konnekt' ? termsType : undefined,
        cNumber:
          registrationType === 'registration' ? registrationNumber : undefined,
        registeredAddress: address,
        signatory: signatory,
        date: formattedDate,
        expiryDate: formattedExpiryDate,
        folderId: targetFolderId,
        companyId: companyId,
      };

      const result = await createTerms(request);

      if (result.download_link) {
        setDownloadLink(result.download_link);
      }

      setDocumentId(result.document_id);
      setSuccess(true);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate Terms');
    } finally {
      setLoading(false);
    }
  };

  const handleFolderSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFolderSearchTerm(event.target.value);
  };

  const hasWorkingFolder = !!company?.working_agreements_folder_id;
  const hasParentFolder = !!company?.parent_folder_id;

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Generating Terms</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
          <DialogContentText align="center">
            Please wait while the Terms are being generated...
          </DialogContentText>
          <DialogContentText
            align="center"
            style={{ marginTop: '8px', fontSize: '0.9em', color: '#666' }}
          >
            This process can take up to a minute to complete.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (success) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Terms Generated Successfully</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.successContainer}>
            <Alert severity="success">Terms generated successfully!</Alert>
            {downloadLink && (
              <Link
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.successLink}
              >
                Click here to download your Terms. Please be sure to upload it
                to the correct Drive folder for visibility.
              </Link>
            )}
            {!downloadLink && documentId && (
              <>
                <Typography variant="body1" className={classes.successLink}>
                  Your Terms have been saved to the selected Drive folder.
                </Typography>
                <Link
                  href={`https://docs.google.com/document/d/${documentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.successLink}
                >
                  Click here to open your document in Google Drive.
                </Link>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate Terms</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          How do you want to save the Terms for {companyName}?
        </DialogContentText>

        {error && (
          <Alert
            severity="error"
            style={{ marginTop: '16px', marginBottom: '16px' }}
          >
            {error}
          </Alert>
        )}

        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            value={generationMethod}
            onChange={(e) =>
              setGenerationMethod(e.target.value as 'drive' | 'download')
            }
          >
            <FormControlLabel
              value="drive"
              control={<Radio />}
              label="Save to Google Drive"
            />
            <FormControlLabel
              value="download"
              control={<Radio />}
              label="Download directly"
            />
          </RadioGroup>
        </FormControl>

        {generationMethod === 'drive' && hasParentFolder && (
          <div>
            {hasWorkingFolder ? (
              <Alert severity="info" style={{ marginTop: '8px' }}>
                The document will be saved to the Working Agreements folder.
              </Alert>
            ) : (
              <Alert severity="info" style={{ marginTop: '8px' }}>
                No Working Agreements folder found. The document will be saved
                to the main company folder.
              </Alert>
            )}
          </div>
        )}

        {generationMethod === 'drive' && !hasParentFolder && (
          <>
            <Alert severity="warning" style={{ marginTop: '8px' }}>
              Couldn't automatically find a Drive folder for this company. Try
              searching for it below. If the folder doesn't exist yet,{' '}
              <Link
                href={`${window.location.origin}/drive-folders`}
                target="_blank"
                rel="noopener noreferrer"
              >
                go to Drive Management
              </Link>{' '}
              to create it. You may want to download the Terms directly and
              upload it to the folder later.
            </Alert>

            <div className={classes.folderSearchBox}>
              <Autocomplete
                options={folderSuggestions}
                getOptionLabel={(option) => option.folder_name}
                value={selectedFolder}
                onChange={(_, newValue) => setSelectedFolder(newValue)}
                loading={loadingFolders}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search for a Google Drive folder"
                    variant="outlined"
                    onChange={handleFolderSearchChange}
                    fullWidth
                    placeholder="Start typing to search for a Drive folder"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingFolders && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps?.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleGenerate}
          color="primary"
          variant="contained"
          disabled={
            generationMethod === 'drive' && !hasParentFolder && !selectedFolder
          }
        >
          Generate Terms
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsGenerationDialog;
