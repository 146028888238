import axios from 'axios';
import { Config } from 'config/Config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  CreateTermsRequest,
  CreateTermsResponse,
  FetchTermsParams,
  FetchTermsResponse,
} from 'lib/Model/TermsGenerator';

export async function createTerms(
  request: CreateTermsRequest,
): Promise<CreateTermsResponse> {
  const response = await axios.post(
    `${Config.API_BASE_URL}intranet/terms_of_business/generate`,
    request,
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function fetchTerms(
  params: FetchTermsParams = {},
): Promise<FetchTermsResponse> {
  const { page = 1, pageSize = 100, searchParam, companyId } = params;

  let url = `${Config.API_BASE_URL}intranet/terms_of_business/terms`;

  if (companyId) {
    url = `${Config.API_BASE_URL}intranet/companies/${companyId}/terms`;
  }

  const response = await axios.get(url, {
    params: {
      page: page,
      page_size: pageSize,
      search_param: searchParam,
    },
    headers: {
      'Content-Type': 'application/json',
      'access-token': getAccessToken(),
    },
  });

  return response.data;
}

export async function updateTermsCompany(
  termsId: number,
  companyId: string,
): Promise<{ message: string; terms_id: number }> {
  const response = await axios.put(
    `${Config.API_BASE_URL}intranet/terms_of_business/terms/${termsId}`,
    { companyId },
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}
