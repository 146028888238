import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Link,
  Typography,
} from '@material-ui/core';
import { fetchNews } from 'lib/Api/News';
import { PagedNews } from 'lib/Model/News';
import { Alert } from '@material-ui/lab';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { useLocation } from 'react-router-dom';
import {
  TableSettings,
  useLocalStorageSettings,
  getTableSettingsStorageKey,
} from 'hooks';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  description: {
    maxWidth: '50vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateColumn: {
    width: 200,
    textAlign: 'center', // Changed from alignItems to textAlign
  },
  headlineColumn: {
    width: 300,
  },
  sourceColumn: {
    width: 150,
  },
  tableCell: {
    paddingLeft: theme.spacing(2),
  },
}));

interface NewsProps {
  companyId: string;
}

export const News: React.FC<NewsProps> = ({ companyId }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<PagedNews | undefined>();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchNews(companyId, pageNumber + 1, tableSettings.rowsPerPage)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [companyId, pageNumber, tableSettings.rowsPerPage]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'published',
      label: 'Published',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'headline',
      label: 'Headline',
      sortable: false,
      props: {
        className: classes.headlineColumn,
      },
    },
    {
      key: 'source',
      label: 'Source',
      sortable: false,
      props: {
        className: classes.sourceColumn,
      },
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="News"
        subheader="Latest news articles related to this company."
      />
      <Alert severity="info" style={{ margin: '0 16px 16px 16px' }}>
        News results are aggregated from various online sources. Some articles
        may not be entirely accurate or relevant to the company. Do not base any
        decisions on the information provided here.
      </Alert>
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((article, index) => ({
            key: `news_${index}`,
            cells: [
              {
                key: 'published',
                display: (
                  <div className={classes.tableCell}>
                    <DateDisplay date={article.time_published} />
                  </div>
                ),
              },
              {
                key: 'headline',
                display: (
                  <Typography variant="h6" component="h3">
                    <Link
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {article.headline}
                    </Link>
                  </Typography>
                ),
              },
              {
                key: 'source',
                display: article.source && (
                  <Link
                    href={article.source.url || undefined}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={article.source.url ? 'primary' : 'textPrimary'}
                  >
                    {article.source.name}
                  </Link>
                ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
    </Card>
  );
};
