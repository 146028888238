import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DriveCompany } from 'lib/Model/Drive';
import { fetchDriveCompanies } from 'lib/Api/Drive';
import { updateTermsCompany } from 'lib/Api/TermsGenerator';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useDebounce } from 'hooks';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 400,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: 'relative',
  },
  companySelector: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface CompanyAssociationDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  termsId: number;
  termsCompanyName: string;
}

export const CompanyAssociationDialog: React.FC<CompanyAssociationDialogProps> =
  ({ open, onClose, onSuccess, termsId, termsCompanyName }) => {
    const classes = useStyles();
    const [companies, setCompanies] = useState<DriveCompany[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<DriveCompany | null>(
      null,
    );
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchQuery = useDebounce(searchQuery, 400);

    const fetchCompanies = useCallback(async (query: string = '') => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchDriveCompanies(query);
        setCompanies(response.data);
      } catch (error) {
        setError('Failed to load companies. Please try again.');
      } finally {
        setLoading(false);
      }
    }, []);

    useEffect(() => {
      if (open) {
        if (debouncedSearchQuery.length >= 2) {
          fetchCompanies(debouncedSearchQuery);
        } else if (debouncedSearchQuery === '') {
          fetchCompanies();
        }
      }
    }, [open, debouncedSearchQuery, fetchCompanies]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchQuery(query);
    };

    const handleClose = () => {
      if (!submitting) {
        setSelectedCompany(null);
        setError(null);
        setSuccess(false);
        setSearchQuery('');
        onClose();
      }
    };

    const handleSubmit = async () => {
      if (!selectedCompany) {
        setError('Please select a company');
        return;
      }

      setSubmitting(true);
      setError(null);
      try {
        await updateTermsCompany(termsId, selectedCompany.id);
        setSuccess(true);
        setTimeout(() => {
          handleClose();
          onSuccess();
        }, 1500);
      } catch (error) {
        setError('Failed to update the terms. Please try again.');
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="company-association-dialog-title"
      >
        <DialogTitle id="company-association-dialog-title">
          Link Terms with Company
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Please select a company to link with the Terms of Business document
            for "{termsCompanyName}". Doing so will make the terms visible in
            this company's Product Stack. The company needs to first be created
            on Zendesk Sell.
          </DialogContentText>

          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">Company linked successfully!</Alert>
          )}

          <Autocomplete
            className={classes.companySelector}
            options={companies}
            getOptionLabel={(option) =>
              `${option.company_title}${
                option.CNumber ? ` (${option.CNumber})` : ''
              }`
            }
            loading={loading}
            value={selectedCompany}
            onChange={(event, newValue) => {
              setSelectedCompany(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Company"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                placeholder="Start typing to search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={submitting}>
            Cancel
          </Button>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={submitting || !selectedCompany || success}
            >
              Link
            </Button>
            {submitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  };

export default CompanyAssociationDialog;
