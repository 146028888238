import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DriveFolder } from 'lib/Model/Drive';
import { fetchDriveFolders } from 'lib/Api/Drive';
import { generateQuoteSlides, checkQuoteFolder } from 'lib/Api/QuoteGenerator';
import { useDebounce } from 'hooks';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '500px',
  },
  formControl: {
    margin: theme.spacing(1, 0, 2),
    width: '100%',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  folderSearchBox: {
    marginTop: theme.spacing(2),
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  successLink: {
    marginTop: theme.spacing(2),
  },
}));

interface QuoteGenerationDialogProps {
  open: boolean;
  quoteId: number | null;
  companyName: string;
  onClose: () => void;
  onSuccess?: () => void;
}

export const QuoteGenerationDialog: React.FC<QuoteGenerationDialogProps> = ({
  open,
  quoteId,
  companyName,
  onClose,
  onSuccess,
}) => {
  const classes = useStyles();
  const [generationMethod, setGenerationMethod] = useState<
    'drive' | 'download'
  >('download');
  const [loading, setLoading] = useState(false);
  const [folderSearchTerm, setFolderSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(folderSearchTerm, 400);
  const [folderSuggestions, setFolderSuggestions] = useState<DriveFolder[]>([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<DriveFolder | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [checkingFolder, setCheckingFolder] = useState(false);
  const [folderExists, setFolderExists] = useState<boolean | null>(null);
  const [folderId, setFolderId] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setGenerationMethod('download');
      setError(null);
      setSuccess(false);
      setDownloadLink(null);
      setDocumentId(null);
      setSelectedFolder(null);
      setFolderSearchTerm('');
      setFolderExists(null);
      setFolderId(null);

      if (quoteId) {
        checkQuoteFolderExists(quoteId);
      }
    }
  }, [open, quoteId]);

  const checkQuoteFolderExists = async (quoteId: number) => {
    if (!quoteId) return;

    setCheckingFolder(true);
    try {
      const result = await checkQuoteFolder(quoteId);
      setFolderExists(result.exists);
      setFolderId(result.folder_id || null);

      if (result.exists) {
        setGenerationMethod('drive');
      }
    } catch (err) {
      setFolderExists(false);
      setFolderId(null);
    } finally {
      setCheckingFolder(false);
    }
  };

  useEffect(() => {
    const fetchFolders = async () => {
      if (debouncedSearchTerm.trim().length > 1) {
        setLoadingFolders(true);
        try {
          const response = await fetchDriveFolders(debouncedSearchTerm);
          setFolderSuggestions(response.data);
        } catch (error) {
          setFolderSuggestions([]);
        } finally {
          setLoadingFolders(false);
        }
      } else {
        setFolderSuggestions([]);
      }
    };

    fetchFolders();
  }, [debouncedSearchTerm]);

  const handleGenerate = async () => {
    if (!quoteId) return;

    setLoading(true);
    setError(null);

    try {
      const targetFolderId =
        generationMethod === 'drive'
          ? folderId || selectedFolder?.id || undefined
          : undefined;

      const result = await generateQuoteSlides(quoteId, targetFolderId);

      if (result.download_link) {
        setDownloadLink(result.download_link);
      }

      setDocumentId(result.document_id);
      setSuccess(true);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to generate Proposal',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFolderSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFolderSearchTerm(event.target.value);
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Generating Slides</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
          <DialogContentText align="center">
            Please wait while the proposal is being generated...
          </DialogContentText>
          <DialogContentText
            align="center"
            style={{ marginTop: '8px', fontSize: '0.9em', color: '#666' }}
          >
            This process can take up to a minute to complete.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (success) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Proposal Generated Successfully</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.successContainer}>
            <Alert severity="success">Proposal generated successfully!</Alert>
            {downloadLink && (
              <Link
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.successLink}
              >
                Click here to download your proposal. Please be sure to upload
                it to the correct Drive folder for visibility.
              </Link>
            )}
            {!downloadLink && documentId && (
              <>
                <Typography variant="body1" className={classes.successLink}>
                  Your proposal has been saved to the selected Drive folder.
                </Typography>
                <Link
                  href={`https://docs.google.com/presentation/d/${documentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.successLink}
                >
                  Click here to open your proposal in Google Slides
                </Link>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate Proposal</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          How do you want to save the proposal for {companyName}?
        </DialogContentText>

        {error && (
          <Alert
            severity="error"
            style={{ marginTop: '16px', marginBottom: '16px' }}
          >
            {error}
          </Alert>
        )}

        {checkingFolder ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={20} />
            <Typography variant="body2" style={{ marginLeft: '8px' }}>
              Checking if a Drive folder exists for this proposal...
            </Typography>
          </div>
        ) : (
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              value={generationMethod}
              onChange={(e) =>
                setGenerationMethod(e.target.value as 'drive' | 'download')
              }
            >
              <FormControlLabel
                value="drive"
                control={<Radio />}
                label="Save to Google Drive"
              />
              <FormControlLabel
                value="download"
                control={<Radio />}
                label="Download directly"
              />
            </RadioGroup>
          </FormControl>
        )}

        {folderExists && folderId && (
          <Alert severity="info" style={{ marginTop: '16px' }}>
            A Drive folder for this proposal was found. The proposal can be
            saved there.
          </Alert>
        )}

        {generationMethod === 'drive' && !folderExists && !checkingFolder && (
          <Alert severity="warning" style={{ marginTop: '8px' }}>
            Couldn't automatically find a Drive folder for this company. Try
            searching for it below. If the folder doesn't exist yet,{' '}
            <Link
              href={`${window.location.origin}/drive-folders`}
              target="_blank"
              rel="noopener noreferrer"
            >
              go to Drive Management
            </Link>{' '}
            to create it. You may want to download the proposal directly and
            upload it to the folder later.
          </Alert>
        )}

        {generationMethod === 'drive' && !folderId && (
          <div className={classes.folderSearchBox}>
            <Autocomplete
              options={folderSuggestions}
              getOptionLabel={(option) => option.folder_name}
              value={selectedFolder}
              onChange={(_, newValue) => setSelectedFolder(newValue)}
              loading={loadingFolders}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for a Google Drive folder"
                  variant="outlined"
                  onChange={handleFolderSearchChange}
                  fullWidth
                  placeholder="Start typing to search for a Drive folder"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingFolders ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleGenerate}
          color="primary"
          variant="contained"
          disabled={
            checkingFolder ||
            (generationMethod === 'drive' && !folderId && !selectedFolder)
          }
        >
          Generate Proposal
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoteGenerationDialog;
