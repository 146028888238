import {
  QuoteResponseLineItem,
  QuoteResponse,
  QuoteRequest,
  PricingTier,
  PagedQuoteHistory,
  QuoteDetailItem,
} from 'lib/Model/QuoteGenerator';
import pricingData from './data/pricing.json';
import { Config } from 'config/Config';
import { getAccessToken } from 'lib/Helper/Auth';
import axios from 'axios';

export function determineTierSize(companySize: number): string {
  const tiers = pricingData.tiers;
  if (
    companySize >= tiers.tier_1.minEmployees &&
    companySize <= tiers.tier_1.maxEmployees
  )
    return 'tier_1';
  if (
    companySize >= tiers.tier_2.minEmployees &&
    companySize <= tiers.tier_2.maxEmployees
  )
    return 'tier_2';
  if (
    companySize >= tiers.tier_3.minEmployees &&
    companySize <= tiers.tier_3.maxEmployees
  )
    return 'tier_3';
  if (
    companySize >= tiers.tier_4.minEmployees &&
    companySize <= tiers.tier_4.maxEmployees
  )
    return 'tier_4';
  return 'Unknown';
}

export function generateQuote(request: QuoteRequest): QuoteResponse {
  const effectiveCompanySize = Math.max(request.companySize, 10);
  const tierKey = determineTierSize(effectiveCompanySize) as PricingTier;

  const CORE_HARDWARE_KEYS = new Set([
    'uA760',
    'silkBio',
    'shared_team_virtual_punchclock',
    'rfid_em_cards',
  ]);

  let modulesItems: QuoteResponseLineItem[] = [];
  let hardwareItems: QuoteResponseLineItem[] = [];
  let extrasItems: QuoteResponseLineItem[] = [];
  let monthlyTotal = 0;
  let setupTotal = 0;
  let unitPriceTotal = 0;
  let atsUnitPriceTotal = 0;

  for (const module of request.modules) {
    const isHardware = CORE_HARDWARE_KEYS.has(module.moduleKey);
    const isATS =
      module.moduleKey === 'admin_licence' ||
      module.moduleKey === 'line_manager_licence';

    const userQty =
      module.userQty !== undefined
        ? module.userQty
        : isHardware
        ? 1
        : module.moduleKey === 'csp_per_payslip'
        ? 0
        : effectiveCompanySize;

    const modulePricing = pricingData.pricing[tierKey];
    const addon = modulePricing.addons.find(
      (a) => a.module === module.moduleKey,
    );

    if (addon) {
      if (isHardware) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const isVirtualPunchclock =
          module.moduleKey === 'shared_team_virtual_punchclock';
        const hardwarePrice = addon.clocking_pro_price ?? addon.price ?? 0;

        if (isVirtualPunchclock) {
          const monthlyPrice = (addon.price ?? 0) * userQty;
          hardwareItems.push({
            moduleKey: module.moduleKey,
            userQty,
            price: monthlyPrice,
            originalPrice: addon.price,
            discountUnitPrice: 0,
            itemType: 'hardware',
            monthlyUnitPrice: addon.price,
            setupFee: addon.setup_price ? addon.setup_price * userQty : 0,
            originalMonthlyUnitPrice: addon.price,
            discountMonthlyUnitPrice: 0,
          });
          monthlyTotal += monthlyPrice;
          setupTotal += (addon.setup_price ?? 0) * userQty;
          unitPriceTotal += addon.price ?? 0;
        } else {
          const effectiveHardwarePrice = Math.max(
            hardwarePrice - discountUnitPrice,
            0,
          );
          const cost = effectiveHardwarePrice * userQty;
          hardwareItems.push({
            moduleKey: module.moduleKey,
            userQty,
            price: cost,
            originalPrice: hardwarePrice,
            discountUnitPrice,
            itemType: 'hardware',
            oneTimeUnitPrice: effectiveHardwarePrice,
            setupFee: addon.setup_price ? addon.setup_price * userQty : 0,
            originalOneTimeUnitPrice: hardwarePrice,
            discountOneTimeUnitPrice: discountUnitPrice,
          });
          setupTotal += cost;
          if (addon.setup_price) {
            setupTotal += addon.setup_price * userQty;
          }
        }
      } else {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const discountSetupFee = module.discountSetupFee ?? 0;
        const originalPrice = addon.price ?? 0;
        const originalSetupFee = addon.setup_price ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const modulePrice = effectivePrice * userQty;

        modulesItems.push({
          moduleKey: module.moduleKey,
          userQty,
          price: modulePrice,
          originalPrice,
          discountUnitPrice,
          originalSetupFee,
          discountSetupFee,
          itemType: 'module',
          monthlyUnitPrice: effectivePrice,
          setupFee: effectiveSetupPrice,
          originalMonthlyUnitPrice: originalPrice,
          discountMonthlyUnitPrice: discountUnitPrice,
        });
        monthlyTotal += modulePrice;
        setupTotal += effectiveSetupPrice;

        if (isATS) {
          atsUnitPriceTotal += effectivePrice;
        } else {
          unitPriceTotal += effectivePrice;
        }
      }
    }

    const extra = pricingData.extras.find((e) => e.key === module.moduleKey);
    if (extra) {
      const originalPrice = extra.price ?? 0;
      const originalSetupFee = extra.setup_price ?? 0;

      if (module.moduleKey === 'signrequest' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price,
          originalPrice,
          discountUnitPrice,
          itemType: 'extra',
          monthlyUnitPrice: effectivePrice,
          originalMonthlyUnitPrice: originalPrice,
          discountMonthlyUnitPrice: discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      } else if (module.moduleKey === 'reporting_database' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price,
          originalPrice,
          discountUnitPrice,
          itemType: 'extra',
          monthlyUnitPrice: effectivePrice,
          originalMonthlyUnitPrice: originalPrice,
          discountMonthlyUnitPrice: discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      } else if (
        module.moduleKey === 'parallel_payroll_runs' &&
        module.userQty
      ) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: 0,
          originalSetupFee,
          discountSetupFee,
          itemType: 'extra',
          oneTimeUnitPrice: effectiveSetupPrice,
          originalOneTimeUnitPrice: originalSetupFee,
          discountOneTimeUnitPrice: discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'data_migration' && module.userQty) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: 0,
          originalSetupFee,
          discountSetupFee,
          itemType: 'extra',
          oneTimeUnitPrice: effectiveSetupPrice,
          originalOneTimeUnitPrice: originalSetupFee,
          discountOneTimeUnitPrice: discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'onboarding_session' && module.userQty) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: setupPrice,
          originalSetupFee,
          discountSetupFee,
          itemType: 'extra',
          oneTimeUnitPrice: effectiveSetupPrice,
          originalOneTimeUnitPrice: originalSetupFee,
          discountOneTimeUnitPrice: discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'visitor_management' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price,
          originalPrice,
          discountUnitPrice,
          itemType: 'extra',
          monthlyUnitPrice: effectivePrice,
          originalMonthlyUnitPrice: originalPrice,
          discountMonthlyUnitPrice: discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      }
    }
  }

  return {
    companyName: request.companyName,
    companyId: request.companyId,
    numberOfCompanies: request.numberOfCompanies || 1,
    requestedBy: request.requestedBy,
    modules: modulesItems,
    hardware: hardwareItems,
    extras: extrasItems,
    monthlyFee: monthlyTotal,
    annualFee: monthlyTotal * 12,
    oneTimeFee: setupTotal,
    totalUnitPrice: unitPriceTotal,
    atsUnitPrice: atsUnitPriceTotal,
  };
}

export async function saveQuote(
  quote: QuoteResponse,
): Promise<{ message: string; quote_id: number }> {
  const response = await axios.post(
    `${Config.API_BASE_URL}intranet/quote_generator/save`,
    quote,
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function fetchQuoteHistory(
  pageNumber: number,
  itemsPerPage: number,
  companyName?: string,
  companyId?: string,
): Promise<PagedQuoteHistory> {
  const response = await axios.get(
    `${Config.API_BASE_URL}intranet/quote_generator/quotes`,
    {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        search_param: companyName,
        company_id: companyId,
      },
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function fetchQuoteDetails(
  quoteId: number,
): Promise<QuoteDetailItem[]> {
  const response = await axios.get(
    `${Config.API_BASE_URL}intranet/quote_generator/quotes/${quoteId}/details`,
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data.data;
}

export async function fetchCompanyQuotes(
  companyId: string,
  pageNumber: number = 1,
  itemsPerPage: number = 10,
): Promise<PagedQuoteHistory> {
  const response = await axios.get(
    `${Config.API_BASE_URL}intranet/companies/${companyId}/quotes`,
    {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
      },
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function updateQuoteCompany(
  quoteId: number,
  companyId: string,
  companyName: string,
): Promise<{ message: string; quote_id: number }> {
  const response = await axios.put(
    `${Config.API_BASE_URL}intranet/quote_generator/quotes/${quoteId}`,
    {
      companyId,
      companyName,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function generateQuoteSlides(
  quoteId: number,
  folderId?: string,
): Promise<{ document_id: string; download_link?: string; quote_id: number }> {
  const response = await axios.post(
    `${Config.API_BASE_URL}intranet/quote_generator/quotes/${quoteId}/generate`,
    { folder_id: folderId },
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}

export async function checkQuoteFolder(
  quoteId: number,
): Promise<{ exists: boolean; quote_id: number; folder_id?: string }> {
  const response = await axios.get(
    `${Config.API_BASE_URL}intranet/quote_generator/quotes/${quoteId}/check_folder`,
    {
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );

  return response.data;
}
