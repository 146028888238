import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { DelayedLinearProgress } from 'components';
import { triggerGoogleLogin } from 'lib/Helper/Auth';
import * as React from 'react';

export const LoginForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Card>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          triggerGoogleLogin();
        }}
      >
        <CardContent>
          <Button
            type="submit"
            size="medium"
            color="primary"
            disabled={loading}
            variant="contained"
            className="login-btn"
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              marginTop: 16,
              marginBottom: 16,
            }}
            fullWidth
          >
            Sign in with your organisation account
          </Button>

          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            style={{ display: 'block', marginTop: 8 }}
          >
            Having trouble? Contact insights@exp42.com for assistance
          </Typography>
        </CardContent>

        <DelayedLinearProgress variant="query" loading={loading} />
      </form>
    </Card>
  );
};
