import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  IconButton,
  CircularProgress,
  Paper,
  Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { fetchTerms } from 'lib/Api/TermsGenerator';
import { TermsDocument, FetchTermsResponse } from 'lib/Model/TermsGenerator';
import { getTableSettingsStorageKey, useLocalStorageSettings } from 'hooks';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    minWidth: '600px',
    minHeight: '400px',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
  tableContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 'calc(100% - 20px)',
    overflow: 'auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  emptyState: {
    textAlign: 'center',
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  dialogPaper: {
    maxWidth: '800px',
    width: '100%',
    maxHeight: '80vh',
  },
  statusActive: {
    backgroundColor: theme.palette.success.light,
    color: 'theme.palette.success.contrastText',
  },
  statusExpired: {
    backgroundColor: theme.palette.error.light,
    color: 'black',
    fontWeight: 'bold',
  },
  statusPending: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    fontWeight: 'bold',
  },
  statusChip: {
    minWidth: '90px',
  },
}));

interface ProductStackTermsProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
}

const ProductStackTerms: React.FC<ProductStackTermsProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [termsHistory, setTermsHistory] = useState<
    FetchTermsResponse | undefined
  >();
  const [pageNumber, setPageNumber] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [tableSettings, setTableSettings] = useLocalStorageSettings(
    getTableSettingsStorageKey('companyTerms', companyId),
    { rowsPerPage: 10 },
  );

  const fetchTermsData = useCallback(async () => {
    if (!companyId || !open) return;

    setLoading(true);
    setError(null);

    try {
      const data = await fetchTerms({
        companyId,
        page: pageNumber + 1,
        pageSize: tableSettings.rowsPerPage,
      });
      setTermsHistory(data);
    } catch (err) {
      setError(
        `Failed to load terms documents: ${
          err instanceof Error ? err.message : 'Unknown error'
        }`,
      );
    } finally {
      setLoading(false);
    }
  }, [companyId, open, pageNumber, tableSettings.rowsPerPage]);

  useEffect(() => {
    fetchTermsData();
  }, [fetchTermsData]);

  const historyColumns: PageableTableHeader[] = [
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'date',
      label: 'Effective Date',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'brand',
      label: 'Brand',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'expiry_date',
      label: 'Expiry Date',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'created_on',
      label: 'Created On',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'created_by',
      label: 'Created By',
      sortable: false,
      props: { align: 'center' },
    },
  ];

  const getTermStatus = (
    createdOn: string | null,
    expiryDate: string | null,
  ) => {
    const today = new Date();
    const createdOnDate = createdOn ? new Date(createdOn) : null;
    const expiryDateTime = expiryDate ? new Date(expiryDate) : null;

    if (createdOnDate && createdOnDate > today) {
      return {
        label: 'Not in effect',
        icon: <HourglassEmptyIcon fontSize="small" />,
        className: classes.statusPending,
      };
    }

    if (!expiryDateTime || expiryDateTime > today) {
      return {
        label: 'Active',
        icon: <CheckCircleIcon fontSize="small" />,
        className: classes.statusActive,
      };
    }

    return {
      label: 'Expired',
      icon: <CancelIcon fontSize="small" />,
      className: classes.statusExpired,
    };
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="terms-dialog-title"
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="terms-dialog-title">
        <Typography variant="h6">Terms of Business for this Company</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : error ? (
          <Typography variant="body1" className={classes.errorMessage}>
            {error}
          </Typography>
        ) : termsHistory?.data && termsHistory.data.length > 0 ? (
          <div className={classes.tableContainer}>
            <Paper>
              <PageableTable
                tableSettings={tableSettings}
                setTableSettings={setTableSettings}
                showSkeletonLoading
                hideColumns={false}
                rows={
                  termsHistory.data.map(
                    (term: TermsDocument, index: number) => {
                      const status = getTermStatus(
                        term.date_of_issue ?? null,
                        term.terms_expiry_date ?? null,
                      );
                      return {
                        key: index.toString(),
                        cells: [
                          {
                            key: 'status',
                            display: (
                              <Chip
                                icon={status.icon}
                                label={status.label}
                                className={`${status.className} ${classes.statusChip}`}
                                size="small"
                              />
                            ),
                            props: { align: 'center' },
                          },
                          {
                            key: 'date',
                            display: term.date_of_issue && (
                              <DateDisplay date={term.date_of_issue} />
                            ),
                            props: { align: 'center' },
                          },
                          {
                            key: 'brand',
                            display: term.terms_brand,
                            props: { align: 'center' },
                          },
                          {
                            key: 'expiry_date',
                            display: term.terms_expiry_date ? (
                              <DateDisplay date={term.terms_expiry_date} />
                            ) : (
                              'Indefinite'
                            ),
                            props: { align: 'center' },
                          },
                          {
                            key: 'created_on',
                            display: <DateDisplay date={term.created_on} />,
                            props: { align: 'center' },
                          },
                          {
                            key: 'created_by',
                            display: term.created_by,
                            props: { align: 'center' },
                          },
                        ],
                      };
                    },
                  ) || []
                }
                loading={loading}
                pageNumber={pageNumber}
                rowCount={termsHistory?.total_count ?? 0}
                columns={historyColumns}
                onChangePage={setPageNumber}
              />
            </Paper>
          </div>
        ) : (
          <div className={classes.emptyState}>
            <Typography variant="body1">
              No generated Terms of Business documents found for this company.
              <br />
              <br />
              This does not necessarily mean there are no terms of business for
              this company, but that none have been generated through the
              intranet yet.
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductStackTerms;
