import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DateFormat } from 'config';
import { format, parseISO } from 'date-fns';
import {
  fetchProductStack,
  fetchCspList,
  fetchDormantItemsCount,
  exportDormantItems,
} from 'lib/Api/ProductStack';
import { ProductStackDetails, CspList } from 'lib/Model/ProductStack';
import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton, Alert } from '@material-ui/lab';
import { EmptyView } from 'components/EmptyView';
import { CustomTooltip } from 'components/CustomTooltip';
import { toMonetary } from 'lib/Utility';
import CspListDialog from './ProductStackCsps';
import ProductStackQuotes from './ProductStackQuotes';
import ProductStackTerms from './ProductStackTerms';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  boldTitle: {
    fontWeight: 500,
    padding: theme.spacing(1),
    clear: 'both',
    display: 'block',
    cursor: 'default',
    textAlign: 'left',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  accordion: {
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  firstColumn: {
    width: '50%',
  },
  noListMargin: {
    margin: '0px',
    padding: '0px',
  },
  listControl: {
    display: 'flex',
    justifyContent: 'left',
  },
  konnektSummary: {
    backgroundColor: '#65B9E8',
    color: 'white',
  },
  talexioSummary: {
    backgroundColor: '#FD6A68',
    color: 'white',
  },
  simSummary: {
    backgroundColor: '#41B9BC',
    color: 'white',
  },
  competitorSummary: {
    backgroundColor: '#3274d6',
    color: 'white',
  },
  jimSummary: {
    backgroundColor: '#004270',
    color: 'white',
  },
  pmSummary: {
    backgroundColor: '#284C68',
    color: 'white',
  },
  cellResponsive: {
    padding: theme.spacing(1, 2),
    '& .MuiTableRow-root': {
      verticalAlign: '0',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0.75),
    },
    '&:last-child': {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0.75),
      },
    },
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const ProductStack: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState<ProductStackDetails[]>([]);
  const [CspClients, setCspClients] = React.useState<CspList[]>([]);
  const [CspListDialogOpen, setCspListDialogOpen] = React.useState(false);
  const [, setCspLoading] = React.useState(false);
  const [dormantItems, setDormantItems] = React.useState<number>(0);
  const [quoteListDialogOpen, setQuoteListDialogOpen] = React.useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = React.useState(false);

  const handleOpenCspDialog = async () => {
    setCspLoading(true);
    try {
      const clients = await fetchCspList(companyId);
      setCspClients(clients);
    } finally {
      setCspLoading(false);
      setCspListDialogOpen(true);
    }
  };

  const handleCloseCspDialog = () => {
    setCspListDialogOpen(false);
  };

  const handleOpenQuotesDialog = () => {
    setQuoteListDialogOpen(true);
  };

  const handleCloseQuotesDialog = () => {
    setQuoteListDialogOpen(false);
  };

  const handleOpenTermsDialog = () => {
    setTermsDialogOpen(true);
  };

  const handleCloseTermsDialog = () => {
    setTermsDialogOpen(false);
  };

  const handleExportDormantItems = async () => {
    setExportLoading(true);
    try {
      await exportDormantItems(companyId);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchData = React.useCallback(() => {
    setLoading(true);
    Promise.all([
      fetchProductStack(companyId, withRelated),
      fetchDormantItemsCount(companyId),
    ])
      .then(([productStackData, dormantItemsCount]) => {
        setData(productStackData);
        setDormantItems(dormantItemsCount);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, withRelated]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  type ModuleData = {
    unitLicensed: number | undefined;
    unitCost: number | undefined;
    actualLastMonth: number | undefined;
    actualLast3Months: number | undefined;
  };

  type ModuleMapping = {
    [moduleName: string]: ModuleData;
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Product Stack"
        subheader="A detailed view of the services this client was or is currently subscribed to.
You can click 'Include data from related companies' in order to view this information at an organisational level."
        action={
          <React.Fragment>
            <Button
              color="primary"
              onClick={handleOpenQuotesDialog}
              style={{ marginRight: 8 }}
            >
              View Proposals
            </Button>
            <Button
              color="primary"
              onClick={handleOpenTermsDialog}
              style={{ marginRight: 8 }}
            >
              View Terms
            </Button>
            <Button color="primary" href="/dashboards#proact">
              PROACT Dashboard
            </Button>
            <Button
              color="secondary"
              href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.8m566ca87l4a"
              target="_blank"
            >
              Help
            </Button>
          </React.Fragment>
        }
      />

      {loading ? (
        <Skeleton variant="rect" style={{ height: 400, width: '100%' }} />
      ) : data.length === 0 ? (
        <EmptyView>
          {
            'Nothing here yet. Expecting some data? Click "Include data from related companies" to view data at an organisational level.'
          }
        </EmptyView>
      ) : (
        data.map((item) => (
          <React.Fragment key={item.organisation_id}>
            {loading ? (
              <Skeleton variant="rect" style={{ height: 400 }} />
            ) : (
              <>
                {item.knt_tob_expiry ||
                item.latest_konnekt_job ||
                item.latest_konnekt_closed ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.konnektSummary}
                    >
                      {' '}
                      <Typography variant="h6">Konnekt</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={classes.cellResponsive}
                              style={{ width: '10%' }}
                            >
                              <div className={classes.listControl}>
                                <CustomTooltip
                                  titleHeader=""
                                  bodyText="Data fetched from Zendesk"
                                  placement="right"
                                  maxWidth={600}
                                  offset="0,15"
                                  useDefaultUnderline={true}
                                  enabledArrow={true}
                                >
                                  Terms Expiry Date
                                </CustomTooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              {item.knt_tob_expiry
                                ? format(
                                    parseISO(item.knt_tob_expiry),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Role Opened
                            </TableCell>
                            <TableCell>
                              {item.latest_konnekt_job
                                ? format(
                                    parseISO(item.latest_konnekt_job),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Role Closing Date
                            </TableCell>
                            <TableCell>
                              {item.latest_konnekt_closed
                                ? format(
                                    parseISO(item.latest_konnekt_closed),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Vacancies Filled Last 6 Months
                            </TableCell>
                            <TableCell>
                              {item.successful_roles_closed_last_6_months ??
                                'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Vacancies Opened Last 6 Months
                            </TableCell>
                            <TableCell>
                              {item.roles_opened_last_6_months ?? 'N/A'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {item.tlx_subscription_company_size ||
                item.tlx_admin_licence_number_of_employees ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.talexioSummary}
                    >
                      <Typography variant="h6">Talexio</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          {dormantItems > 0 && (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <Alert
                                  severity="warning"
                                  action={
                                    <Button
                                      startIcon={<GetAppIcon />}
                                      onClick={handleExportDormantItems}
                                      disabled={exportLoading}
                                    >
                                      {exportLoading
                                        ? 'Downloading...'
                                        : 'Download Audit Report'}
                                    </Button>
                                  }
                                >
                                  This client has shown dormant activity on{' '}
                                  {dormantItems} items.
                                </Alert>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Licensing Mode
                            </TableCell>
                            <TableCell>
                              {item.tlx_is_csp === 1 ? (
                                <div>
                                  Agency or Service Provider
                                  <Button
                                    variant="text"
                                    color="primary"
                                    style={{
                                      background: 'none',
                                    }}
                                    onClick={handleOpenCspDialog}
                                  >
                                    Show CSP List
                                  </Button>
                                </div>
                              ) : item.tlx_is_csp_client === 1 ? (
                                'Client of Service Provider'
                              ) : item.tlx_is_csp === 0 &&
                                item.tlx_is_csp_client === 0 ? (
                                'Internal HR'
                              ) : (
                                'No Paid Subscription'
                              )}
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Admin Users
                            </TableCell>
                            <TableCell colSpan={10}>
                              {item.contact_person
                                ? item.contact_person
                                    .split(',')
                                    .map((admin, index) => (
                                      <li key={index}>{admin.trim()}</li>
                                    ))
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Subscription Status
                            </TableCell>
                            <TableCell>
                              <div>
                                {item.tlx_subscription_end_date !== '' &&
                                item.tlx_subscription_end_date !== null ? (
                                  <span>Cancelled</span>
                                ) : item.tlx_next_renewal_date &&
                                  new Date(item.tlx_next_renewal_date) <
                                    new Date() ? (
                                  <span>Expired</span>
                                ) : (item.tlx_subscription_start_date ===
                                    null ||
                                    item.tlx_subscription_start_date === '') &&
                                  (item.tlx_modules === null ||
                                    item.tlx_modules === '') &&
                                  ((item.tlx_subscription_company_size ||
                                    item.tlx_admin_licence_number_of_employees) ??
                                    0) > 0 ? (
                                  <span>Free ATS</span>
                                ) : (item.tlx_hr_essentials_leave_number_of_employees !==
                                    undefined ||
                                    item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                      undefined) &&
                                  item.tlx_actual_employees_last_month !==
                                    undefined ? (
                                  item.tlx_actual_employees_last_month > 0 &&
                                  (item.tlx_actual_employees_last_month ===
                                    item.tlx_hr_essentials_leave_number_of_employees ||
                                    item.tlx_actual_employees_last_month ===
                                      item.tlx_payroll_leave_hr_essentials_number_of_employees) ? (
                                    <span
                                      style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      At Limit
                                    </span>
                                  ) : (item.tlx_actual_employees_last_month ||
                                      item.tlx_admin_licence_number_of_employees) !==
                                    0 ? (
                                    <span>Active</span>
                                  ) : (
                                    'No Paid Subscription'
                                  )
                                ) : (
                                  <span>N/A</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Subscription Start Date
                            </TableCell>
                            <TableCell>
                              {item.tlx_subscription_start_date
                                ? format(
                                    parseISO(item.tlx_subscription_start_date),
                                    'dd-MMM-yy',
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Next Renewal Date
                            </TableCell>
                            <TableCell>
                              {item.tlx_next_renewal_date
                                ? format(
                                    parseISO(item.tlx_next_renewal_date),
                                    'dd-MMM-yy',
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Team Voice Start Date
                            </TableCell>
                            <TableCell>
                              {item.talexio_tv_start_date
                                ? format(
                                    parseISO(item.talexio_tv_start_date),
                                    'dd-MMM-yy',
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Support Package
                            </TableCell>
                            <TableCell>
                              {toMonetary(item.tlx_support_price || 'N/A')}
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: '#efefef' }}>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Module
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Licensed by
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Units Licensed
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Unit Cost (EUR) per Month
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Actual Units (Last Month)
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              Actual Units (Last 3 Months)
                            </TableCell>
                          </TableRow>
                          {[
                            'HR Essentials & Leave Management',
                            'Payroll, Leave Management & HR Essentials',
                            'HR - Time & Attendance',
                            'HR - Performance',
                            'HR - Training',
                            'ATS',
                            'Cloud Device Management',
                          ].map((module) => {
                            let moduleMapping: ModuleMapping = {};

                            if (
                              'tlx_hr_essentials_leave_number_of_employees' in
                              item
                            ) {
                              moduleMapping[
                                'HR Essentials & Leave Management'
                              ] = {
                                unitLicensed:
                                  item.tlx_hr_essentials_leave_number_of_employees,
                                unitCost: item.tlx_hr_essentials_leave_price,
                                actualLastMonth:
                                  item.tlx_actual_employees_last_month,
                                actualLast3Months:
                                  item.tlx_actual_employees_last_3_month,
                              };
                            }

                            if (
                              'tlx_payroll_leave_hr_essentials_number_of_employees' in
                              item
                            ) {
                              moduleMapping[
                                'Payroll, Leave Management & HR Essentials'
                              ] = {
                                unitLicensed:
                                  item.tlx_payroll_leave_hr_essentials_number_of_employees,
                                unitCost:
                                  item.tlx_payroll_leave_hr_essentials_price,
                                actualLastMonth:
                                  'HR Essentials & Leave Management' in
                                    moduleMapping &&
                                  moduleMapping[
                                    'HR Essentials & Leave Management'
                                  ].unitLicensed === 0 &&
                                  item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                    0 &&
                                  item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                    null
                                    ? moduleMapping[
                                        'HR Essentials & Leave Management'
                                      ].actualLastMonth
                                    : undefined,
                                actualLast3Months:
                                  'HR Essentials & Leave Management' in
                                    moduleMapping &&
                                  moduleMapping[
                                    'HR Essentials & Leave Management'
                                  ].unitLicensed === 0 &&
                                  item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                    0 &&
                                  item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                    null
                                    ? moduleMapping[
                                        'HR Essentials & Leave Management'
                                      ].actualLast3Months
                                    : undefined,
                              };

                              if (
                                'HR Essentials & Leave Management' in
                                  moduleMapping &&
                                moduleMapping[
                                  'HR Essentials & Leave Management'
                                ].unitLicensed === 0 &&
                                item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                  0 &&
                                item.tlx_payroll_leave_hr_essentials_number_of_employees !==
                                  null
                              ) {
                                moduleMapping[
                                  'HR Essentials & Leave Management'
                                ].actualLastMonth = undefined;
                                moduleMapping[
                                  'HR Essentials & Leave Management'
                                ].actualLast3Months = undefined;
                              }
                            }

                            moduleMapping['HR - Time & Attendance'] = {
                              unitLicensed:
                                item.tlx_time_and_attendance_number_of_employees,
                              unitCost: item.tlx_time_and_attendance_price,
                              actualLastMonth:
                                item.tlx_actual_ta_employees_last_month,
                              actualLast3Months:
                                item.tlx_actual_ta_employees_last_3_month,
                            };
                            moduleMapping['HR - Performance'] = {
                              unitLicensed:
                                item.tlx_performance_number_of_employees,
                              unitCost: item.tlx_performance_price,
                              actualLastMonth: undefined,
                              actualLast3Months: undefined,
                            };
                            moduleMapping['HR - Training'] = {
                              unitLicensed:
                                item.tlx_training_management_number_of_employees,
                              unitCost: item.tlx_training_management_price,
                              actualLastMonth: undefined,
                              actualLast3Months: undefined,
                            };
                            moduleMapping['ATS'] = {
                              unitLicensed:
                                (item.tlx_admin_licence_number_of_employees ||
                                  0) +
                                (item.tlx_additional_admin_licence_number_of_employees ||
                                  0),
                              unitCost: item.tlx_admin_licence_price,
                              actualLastMonth: undefined,
                              actualLast3Months: undefined,
                            };

                            moduleMapping['Cloud Device Management'] = {
                              unitLicensed:
                                item.tlx_cloud_device_manage_number_of_employees,
                              unitCost: item.tlx_cloud_device_manage_price,
                              actualLastMonth: undefined,
                              actualLast3Months: undefined,
                            };

                            if (module in moduleMapping) {
                              const moduleData = moduleMapping[module];
                              const isLicensedByAdmin = ['ATS'].includes(
                                module,
                              );
                              return (
                                <TableRow key={module}>
                                  <TableCell>{module}</TableCell>
                                  <TableCell>
                                    {isLicensedByAdmin
                                      ? 'HR Managers'
                                      : 'Users'}
                                  </TableCell>
                                  <TableCell>
                                    {moduleData.unitLicensed ?? '-'}
                                  </TableCell>
                                  <TableCell>
                                    {moduleData.unitCost
                                      ? `€${moduleData.unitCost.toFixed(2)}`
                                      : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {moduleData.actualLastMonth ?? '-'}
                                  </TableCell>
                                  <TableCell>
                                    {moduleData.actualLast3Months ?? '-'}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {item.first_jim_job || item.jim_package_expiry ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.jimSummary}
                    >
                      {' '}
                      <Typography variant="h6">Jobsinmalta</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              First Listing Opened
                            </TableCell>
                            <TableCell>
                              {item.first_jim_job
                                ? format(
                                    parseISO(item.first_jim_job),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Listing Created
                            </TableCell>
                            <TableCell>
                              {item.latest_jim_job
                                ? format(
                                    parseISO(item.latest_jim_job),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Listing Published
                            </TableCell>
                            <TableCell>
                              {item.jim_latest_publishing
                                ? format(
                                    parseISO(item.jim_latest_publishing),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Wallet Balance
                            </TableCell>
                            <TableCell>
                              {(item?.balance ?? 0) > 1000
                                ? 'Unlimited'
                                : item?.balance ?? 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              <CustomTooltip
                                titleHeader=""
                                bodyText="Note: This date refers to the expiry of the latest bulk or unlimited package. For an overview on the client's unlimited package, please refer to the Wallet section."
                                placement="right"
                                maxWidth={600}
                                offset="0,15"
                                useDefaultUnderline={true}
                                enabledArrow={true}
                              >
                                Package Expiry Date
                              </CustomTooltip>
                            </TableCell>
                            <TableCell>
                              {item.jim_package_expiry
                                ? format(
                                    parseISO(item.jim_package_expiry),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {item.pm_first_invoice ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.pmSummary}
                    >
                      {' '}
                      <Typography variant="h6">Payroll Malta</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              First Invoice
                            </TableCell>
                            <TableCell>
                              {item.pm_first_invoice
                                ? format(
                                    parseISO(item.pm_first_invoice),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Invoice
                            </TableCell>
                            <TableCell>
                              {item.pm_latest_invoice
                                ? format(
                                    parseISO(item.pm_latest_invoice),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Subscription Status
                            </TableCell>
                            <TableCell>
                              {item.pm_4_months_before_expiry === 1
                                ? 'About to Expire'
                                : item.pm_4_months_before_expiry === 0
                                ? 'Active'
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {item.sim_first_subscription ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.simSummary}
                    >
                      {' '}
                      <Typography variant="h6">Salariesinmalta</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              First Subscription
                            </TableCell>
                            <TableCell>
                              {item.sim_first_subscription
                                ? format(
                                    parseISO(item.sim_first_subscription),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Target Renewal Date
                            </TableCell>
                            <TableCell>
                              {item.sim_target_renewal_date
                                ? format(
                                    parseISO(item.sim_target_renewal_date),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>

                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Expiry Date
                            </TableCell>
                            <TableCell>
                              {item.sim_subscription_expiry
                                ? format(
                                    parseISO(item.sim_subscription_expiry),
                                    DateFormat.EXCEL,
                                  )
                                : 'N/A'}
                            </TableCell>{' '}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Latest Subscription Type
                            </TableCell>
                            <TableCell>
                              {item.sim_subscription_type === '1'
                                ? 'Free Plan'
                                : item.sim_subscription_type === '2'
                                ? 'Premium Plan'
                                : 'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Subscription Status
                            </TableCell>
                            <TableCell>
                              {item.sim_subscription_expiry &&
                              new Date(item.sim_subscription_expiry) <
                                new Date()
                                ? 'Expired'
                                : item.sim_4_months_before_expiry === 1 &&
                                  item.sim_subscription_expiry &&
                                  new Date() <
                                    new Date(item.sim_subscription_expiry)
                                ? 'About to Expire'
                                : 'Active'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {item.competitor_products ? (
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.competitorSummary}
                    >
                      {' '}
                      <Typography variant="h6">Competitor Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.firstColumn}>
                              Products
                            </TableCell>
                            <TableCell>{item.competitor_products}</TableCell>{' '}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </>
            )}
          </React.Fragment>
        ))
      )}
      <CspListDialog
        open={CspListDialogOpen}
        cspClients={CspClients}
        onClose={handleCloseCspDialog}
        loading={loading}
      />
      <ProductStackQuotes
        open={quoteListDialogOpen}
        onClose={handleCloseQuotesDialog}
        companyId={companyId}
      />
      <ProductStackTerms
        open={termsDialogOpen}
        onClose={handleCloseTermsDialog}
        companyId={companyId}
      />
    </Card>
  );
};
