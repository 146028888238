import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  CspList,
  ProductStackDetails,
  DormantItemsCount,
} from 'lib/Model/ProductStack';

export function fetchProductStack(
  companyId: string,
  withRelated: boolean,
): Promise<ProductStackDetails[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack`,
      {
        params: {
          company_id: companyId,
          show_related: withRelated,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as ProductStackDetails[]);
}

export function fetchCspList(companyId: string): Promise<CspList[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack/csp_clients`,
      {
        params: {
          company_id: companyId,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as CspList[]);
}

export function fetchDormantItemsCount(companyId: string): Promise<number> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack/dormant_items`,
      {
        params: {
          company_id: companyId,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => (response.data as DormantItemsCount).data);
}

export function exportDormantItems(companyId: string): Promise<void> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/product_stack/dormant_items/export`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `dormant_items_${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}

export function exportAllDormantItems(): Promise<void> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/product_stack/dormant_items/export`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `all_dormant_items_${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}
