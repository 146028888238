import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { AppTabs, EmptyView, HelpPopper, RelatedCompanyChip } from 'components';
import { useDocumentTitle } from 'hooks';
import Flag from '@material-ui/icons/Flag';
import { fetchClient, fetchMbrUuid } from 'lib/Api/Clients';
import { stringAvatar } from 'lib/Helper/Avatars';
import CloudIcon from '@material-ui/icons/Cloud';
import BusinessIcon from '@material-ui/icons/Business';
import { Client } from 'lib/Model/Client';
import React, { useEffect, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Activity } from './Activity';
import { Contacts } from './Contacts';
import { Invoices } from './Invoices';
import { ClientNpsView } from './Nps';
import { MergedCompanies } from './MergedCompanies';
import { RelatedSuggestions } from './RelatedSuggestions';
import {
  resolvePlatformNameFromPrefix,
  SuggestedMatches,
} from './SuggestedMatches';
import { Wallet } from './Wallet';
import { fetchIntraMergesForCompany } from 'lib/Api/Matches';
import { IntraMerges } from 'lib/Model/Merge';
import { ProductStack } from './ProductStack';
import { KeyboardArrowDown } from '@material-ui/icons';
import { News } from './News';
interface Params {
  id: string;
}

enum ClientDetailsTabs {
  ACTIVITY = 'activity',
  NEWS = 'news',
  WALLET = 'wallet',
  INVOICES = 'invoices',
  NPS = 'nps',
  INSIGHTS = 'insights',
  MATCH = 'match',
  CONTACTS = 'contacts',
  DETAILS = 'details',
  PRODUCTSTACK = 'productstack',
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
  },
  logoContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    height: '100%',
    minHeight: 200,
    [theme.breakpoints.down('xs')]: {
      minHeight: 120,
      fontSize: 32,
      padding: theme.spacing(2),
    },
  },
  companyInfoContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  companyHeader: {
    marginBottom: theme.spacing(2),
  },
  titleWithFlag: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  },
  industryChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statusChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  accountManager: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& .MuiButtonGroup-root': {
        width: '100%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
  platformButton: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  warningBanner: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  alertStyle: {
    marginBottom: theme.spacing(1),
  },
  parentCompany: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  lostChip: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.light,
    border: 0,
  },
  warningChip: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.light,
    border: 0,
  },
  activeChip: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,
    border: 0,
  },
  okChip: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: 0,
  },
  alertChip: {
    color: '#000000',
    backgroundColor: '#ed7e0e',
    border: 0,
  },
  companyInfoHeaderText: {
    padding: theme.spacing(2, 2),
  },
  flagTooltip: {
    fontSize: theme.typography.fontSize * 1,
  },
  flag: {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.fontSize * 3,
  },
  smallAvatar: {
    marginRight: theme.spacing(0.5),
    width: 22,
    height: 22,
    fontSize: 12,
  },
  interactionChip: {
    marginLeft: theme.spacing(0.5),
  },
  gradeChip: {
    marginLeft: theme.spacing(0.5),
  },
  alertAvatar: {
    marginRight: theme.spacing(0.5),
    width: 15,
    height: 15,
    fontSize: 12,
    backgroundColor: '#B92100',
    color: 'white',
    marginBottom: theme.spacing(0.5),
  },
  buttonGroup: {
    margin: theme.spacing(0.2, 0.5, 0, 0),
    alignItems: 'flex-end',
  },
  churnChip: {
    marginRight: theme.spacing(0.5),
  },
  relatedCompaniesNotice: {
    marginBottom: theme.spacing(1),
  },
  helpPopperText: {
    marginBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'left',
  },
  accordion: {
    maxWidth: 500,
    marginBottom: theme.spacing(1),
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  accordionDropdown: {
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.fontSize * 1,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.main,
  },
}));

export const ClientDetails: React.FC = () => {
  const match = useRouteMatch<Params>();

  const { id } = useParams<Params>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [intraMerges, setIntraMerges] = useState<IntraMerges[]>([]);
  const [activeTab] = useState(ClientDetailsTabs.ACTIVITY);
  const [disableMbr, setDisableMbr] = useState(false);

  const handleFetchMbr = (cNumber: string) => {
    setDisableMbr(true);
    fetchMbrUuid(cNumber).then((mbrUuid) => {
      window.open(
        `https://register.mbr.mt/app/query/get_company_details?auto_load=true&uuid=${mbrUuid}`,
        '_blank',
      );
      setDisableMbr(false);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showRelatedData, setShowRelatedData] = useState(false);

  const classes = useStyles();
  const tabs = [
    { label: 'Activity', value: ClientDetailsTabs.ACTIVITY },
    { label: 'News', value: ClientDetailsTabs.NEWS },
    { label: 'Product Stack', value: ClientDetailsTabs.PRODUCTSTACK },
    { label: 'Wallet', value: ClientDetailsTabs.WALLET },
    { label: 'Invoices', value: ClientDetailsTabs.INVOICES },
    { label: 'Contacts', value: ClientDetailsTabs.CONTACTS },
    { label: 'NPS', value: ClientDetailsTabs.NPS },
    { label: 'Linked Companies', value: ClientDetailsTabs.MATCH },
  ];

  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<Client | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchClient(id)
      .then((d) => {
        setLoading(false);
        if (d.id !== id) {
          history.push(`/clients/${d.id}`);
          return;
        }
        setData(d);
      })
      .catch((e) => {
        setData(undefined);
        setLoading(false);
      });
  }, [setLoading, setData, id, history]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    if (activeTab === ClientDetailsTabs.MATCH && data?.id) {
      setLoading(true);
      fetchIntraMergesForCompany(data.id)
        .then((fetchedIntraMerges) => {
          setIntraMerges(fetchedIntraMerges);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [activeTab, data?.id, setLoading]);

  useEffect(() => {
    if (data?.id) {
      fetchIntraMergesForCompany(data.id).then(setIntraMerges);
    }
  }, [data?.id]);

  useDocumentTitle(data?.company_title ?? undefined);

  if (loading) {
    return <Skeleton style={{ height: 300 }} variant="rect" />;
  }

  if (!data) {
    return <EmptyView />;
  }

  const zendeskLeader = data.platforms?.find(
    (p) => p.platform === 'zendesk',
  )?.zendesk_leader;

  const zendeskGroupCompanyTitle = data.platforms?.find(
    (p) => p.platform === 'zendesk',
  )?.zendesk_group_company_title;

  const zendeskGroupCompanyId = data.platforms?.find(
    (p) => p.platform === 'zendesk',
  )?.zendesk_group_company_id;

  const linkedinLink = data.platforms?.find(
    (p) => p.platform === 'zendesk',
  )?.linkedin_link;

  const facebookLink = data.platforms?.find(
    (p) => p.platform === 'zendesk',
  )?.facebook_link;

  const blacklisted = data.platforms?.find(
    (p) => p.blacklisted !== null,
  )?.blacklisted;

  const greylisted = data.platforms?.find(
    (p) => p.greylisted !== null,
  )?.greylisted;

  const listingReasons = data.platforms?.find(
    (p) => p.blacklisted_reasons !== null,
  )?.blacklisted_reasons;

  const websites = data.platforms
    ?.filter((p) => p.company_website)
    .map((p) => p.company_website!.replace(/\/$/, '').trim())
    .filter(onlyUnique);

  const industries = data.platforms
    ?.filter((p) => p.industry)
    .map((p) => p.industry!.trim())
    .filter(onlyUnique);

  const churnSegments = data.platforms
    ?.filter((p) => p.churn_segment)
    .map((p) =>
      `${resolvePlatformNameFromPrefix(p.platform).replace(' ATS', '')}: ${
        p.churn_segment
      }`!.trim(),
    )
    .filter(onlyUnique);

  const driveFolders = data.platforms
    ?.filter((p) => p.drive_folder_url)
    .map((p) => `https://drive.google.com/drive/folders/${p.drive_folder_url!}`)
    .filter(onlyUnique);

  const cNumbers = data.platforms
    ?.filter((p) => p.c_number)
    .map((p) => p.c_number!)
    .filter(onlyUnique);

  const vatNumbers = data.platforms
    ?.filter((p) => p.vat_number)
    .map((p) => p.vat_number!)
    .filter(onlyUnique);

  const grade = data.platforms
    ?.filter((p) => p.grade)
    .map((p) => p.grade!)
    .filter(onlyUnique);

  const interactionStatus = data.platforms
    ?.filter((p) => p.interaction_status)
    .map((p) => p.interaction_status!)
    .filter(onlyUnique);

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{data.company_title}</BreadcrumbsItem>
      )}

      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <div
              className={classes.logoContainer}
              {...stringAvatar(data.company_title)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={10}>
            <div className={classes.companyInfoContainer}>
              <div className={classes.companyHeader}>
                <div className={classes.headerActions}>
                  <div className={classes.titleWithFlag}>
                    <Typography variant="h4" component="h1">
                      {data.company_title}
                    </Typography>
                  </div>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e, checked) => setShowRelatedData(checked)}
                          checked={showRelatedData}
                        />
                      }
                      label="Include data from related companies"
                    />
                  </FormControl>
                </div>

                {zendeskGroupCompanyId && (
                  <div className={classes.infoItem}>
                    <Typography variant="subtitle1">Parent Company:</Typography>
                    <RelatedCompanyChip
                      companyId={zendeskGroupCompanyId}
                      companyName={zendeskGroupCompanyTitle}
                    />
                  </div>
                )}
              </div>

              {industries && industries.length > 0 && (
                <div className={classes.industryChips}>
                  {industries.map((industry, index) => (
                    <Chip
                      key={`industry_${index}`}
                      label={industry}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </div>
              )}

              {churnSegments && churnSegments.length > 0 && (
                <div className={classes.statusChips}>
                  {churnSegments.map((segment, index) => (
                    <Chip
                      key={`segment_${index}`}
                      label={segment}
                      size="small"
                      className={getClassForChip(segment)}
                    />
                  ))}
                  <HelpPopper>
                    <Typography
                      variant="body2"
                      className={classes.helpPopperText}
                    >
                      The following statuses indicate the level of recency with
                      the client.
                    </Typography>
                    <Divider />
                    <List disablePadding dense>
                      <ListItem>
                        <ListItemText
                          primary="Active / Ok"
                          secondary="The client has recently or is currently making use of our services."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Warning"
                          secondary="A moderate amount of time has passed since the client used our services."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Alert"
                          secondary="A considerable amount of time has passed since the client used our services."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Lost"
                          secondary="The client is no longer using our services."
                        />
                      </ListItem>
                    </List>
                  </HelpPopper>
                </div>
              )}

              {zendeskLeader ? (
                <div className={classes.accountManager}>
                  <Avatar {...stringAvatar(zendeskLeader)} />
                  <Typography variant="body1">{zendeskLeader}</Typography>
                  {grade && grade.length > 0 && (
                    <Chip size="small" label={grade[0]} color="primary" />
                  )}
                  {interactionStatus && interactionStatus.length > 0 && (
                    <Chip size="small" label={interactionStatus[0]} />
                  )}
                  <HelpPopper>
                    <List disablePadding dense>
                      <ListItem>
                        <ListItemText
                          primary="Grade A"
                          secondary="Over €7,500 in revenue in the past 2 years, 50+ employees, 8+ meetings last year, or 9+ vacancies filled."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Grade B"
                          secondary="Either over €7,500 in revenue in the past 2 years; or €290+ in revenue with 50+ employees; or 4+ meetings and 4+ vacancies filled last year."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Grade C"
                          secondary="At least €290 in revenue in the past 2 years and one meeting last year."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Grade D"
                          secondary="All other remaining prospects."
                        />
                      </ListItem>
                    </List>

                    <Divider light />

                    <List disablePadding dense>
                      <ListItem>
                        <ListItemText
                          primary="Engaged"
                          secondary="Client had a recent, productive engagement with their CAM/CAS."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Neglected"
                          secondary="Client has not had a recent, productive engagement with their CAM/CAS."
                        />
                      </ListItem>
                    </List>
                  </HelpPopper>
                </div>
              ) : (
                <div className={classes.accountManager}>
                  <Avatar className={classes.alertAvatar}>!</Avatar>
                  <Typography variant="body1">No CAM</Typography>
                  <HelpPopper style={{ padding: 8 }}>
                    <Typography variant="body2" gutterBottom>
                      If no CAM is assigned, it's likely because the Zendesk
                      Sell profile for this company needs to be merged, or that
                      this company does not exist on Zendesk Sell.
                    </Typography>
                  </HelpPopper>
                </div>
              )}

              <div className={classes.actionButtons}>
                <ButtonGroup>
                  <Button
                    className={classes.platformButton}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDown />}
                  >
                    Platform Links
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {data.platforms?.map((p, index) => (
                      <MenuItem onClick={handleClose} key={`menu_${index}`}>
                        <Button
                          href={p.link ?? ''}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {resolvePlatformNameFromPrefix(p.platform).replace(
                            '_',
                            ' ',
                          )}
                        </Button>
                      </MenuItem>
                    ))}
                  </Menu>
                </ButtonGroup>

                {driveFolders && Boolean(driveFolders.length) && (
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    className={classes.buttonGroup}
                  >
                    {driveFolders.map((w, index) => (
                      <Button
                        key={`folder_${index}`}
                        href={w ?? ''}
                        target="_blank"
                        rel="noopener noreferrer"
                        startIcon={<CloudIcon />}
                      >
                        Drive
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
                <ButtonGroup
                  disableElevation
                  className={classes.buttonGroup}
                  variant="contained"
                >
                  {cNumbers && cNumbers.length === 1 && (
                    <Button
                      onClick={() => handleFetchMbr(cNumbers[0])}
                      disableElevation
                      startIcon={<BusinessIcon />}
                      disabled={disableMbr}
                    >
                      MBR
                    </Button>
                  )}
                  {linkedinLink && (
                    <Button
                      startIcon={<LinkedInIcon />}
                      href={linkedinLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </Button>
                  )}
                  {facebookLink && (
                    <Button
                      startIcon={<FacebookIcon />}
                      href={facebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Button>
                  )}
                </ButtonGroup>
                {websites && Boolean(websites.length) && (
                  <ButtonGroup disableElevation variant="contained">
                    {websites && Boolean(websites.length) && (
                      <ButtonGroup
                        disableElevation
                        variant="contained"
                        className={classes.buttonGroup}
                      >
                        {[
                          ...new Set(
                            websites.map((w) => {
                              try {
                                return new URL(w.replace('www.', '')).hostname;
                              } catch (error) {
                                return '';
                              }
                            }),
                          ),
                        ].map(
                          (domain, index) =>
                            domain && (
                              <Button
                                key={`domain_${index}`}
                                href={`https://${domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                startIcon={<LanguageIcon />}
                              >
                                {domain}
                              </Button>
                            ),
                        )}
                      </ButtonGroup>
                    )}
                  </ButtonGroup>
                )}
              </div>

              <div className={classes.companyInfo}>
                {(cNumbers && cNumbers.length > 0) ||
                (vatNumbers && vatNumbers.length > 0) ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    {cNumbers &&
                      cNumbers.length > 0 &&
                      new Set(cNumbers).size > 1 && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ marginRight: '5px' }}
                            gutterBottom
                          >
                            This profile has multiple C-Numbers.
                          </Typography>
                          <HelpPopper style={{ marginLeft: '5px' }}>
                            <Typography variant="body2" gutterBottom>
                              Some profiles may have been merged incorrectly, or
                              there is an incorrect C-Number on one of our
                              systems.
                            </Typography>
                          </HelpPopper>
                        </div>
                      )}

                    {cNumbers && cNumbers.length > 0 && (
                      <Typography
                        variant="caption"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        Registration Number: {cNumbers.join(', ')}
                      </Typography>
                    )}

                    {vatNumbers && vatNumbers.length > 0 && (
                      <Typography variant="caption">
                        VAT Number: {vatNumbers.join(', ')}
                      </Typography>
                    )}

                    {blacklisted && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Flag
                          style={{
                            color: 'black',
                            fontSize: '2.5em',
                          }}
                        />
                        <CustomTooltip
                          titleHeader="Listing Reasons"
                          bodyText={listingReasons}
                          placement="top"
                          maxWidth={600}
                          enabledArrow={true}
                        >
                          <Typography
                            variant="caption"
                            style={{
                              marginLeft: '10px',
                              borderBottom: '1px dotted',
                              cursor: 'pointer',
                            }}
                          >
                            Blacklisted by:{' '}
                            {blacklisted
                              .slice(1, -1)
                              .replace(/'/g, '')
                              .split(',')
                              .map((item) => item.trim())
                              .join(', ')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    )}

                    {greylisted && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Flag
                          style={{
                            color: 'grey',
                            fontSize: '2.5em',
                          }}
                        />
                        <CustomTooltip
                          titleHeader="Listing Reasons"
                          bodyText={listingReasons}
                          placement="top"
                          maxWidth={600}
                          enabledArrow={true}
                        >
                          <Typography
                            variant="caption"
                            style={{
                              marginLeft: '10px',
                              borderBottom: '1px dotted',
                              cursor: 'pointer',
                            }}
                          >
                            Greylisted by:{' '}
                            {greylisted
                              .slice(1, -1)
                              .replace(/'/g, '')
                              .split(',')
                              .map((item) => item.trim())
                              .join(', ')}
                          </Typography>
                        </CustomTooltip>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
      {showRelatedData && (
        <Alert
          variant="outlined"
          severity="info"
          className={classes.relatedCompaniesNotice}
        >
          <Typography variant="body1">
            Displaying information from all related companies.
          </Typography>
          <Typography variant="caption">
            Organisation level data is currently unavailable for NPS, Support
            Tickets and Client Wallet.
          </Typography>
        </Alert>
      )}
      <>
        {intraMerges && intraMerges.length > 0 && (
          <Alert
            variant="outlined"
            className={classes.alertStyle}
            severity="warning"
          >
            Duplicate companies from the same platform are merged into this
            profile, which might result in irregular data.&nbsp;
            <a
              href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.c98twlsvssnb"
              target="_blank"
              rel="noreferrer"
            >
              What should I do?
            </a>
          </Alert>
        )}
      </>
      <AppTabs tabs={tabs} centered>
        {(activeTab) => {
          switch (activeTab) {
            case ClientDetailsTabs.ACTIVITY:
              return (
                <Activity
                  companyId={data.id}
                  withRelated={showRelatedData}
                  meetingNotes={
                    data.platforms && data.platforms.length > 0
                      ? data.platforms[0].meeting_notes
                      : undefined
                  }
                />
              );
            case ClientDetailsTabs.NEWS:
              return <News companyId={data.id} />;
            case ClientDetailsTabs.WALLET:
              return (
                <Wallet companyId={data.id} withRelated={showRelatedData} />
              );
            case ClientDetailsTabs.INVOICES:
              return (
                <Invoices companyId={data.id} withRelated={showRelatedData} />
              );
            case ClientDetailsTabs.NPS:
              return <ClientNpsView companyId={data.id} />;
            case ClientDetailsTabs.PRODUCTSTACK:
              return (
                <ProductStack
                  companyId={data.id}
                  withRelated={showRelatedData}
                />
              );
            case ClientDetailsTabs.CONTACTS:
              return (
                <Contacts companyId={data.id} withRelated={showRelatedData} />
              );
            case ClientDetailsTabs.MATCH:
              return (
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <SuggestedMatches
                      companyId={data.id}
                      doCompanyRefresh={() => {
                        fetchData();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MergedCompanies
                      companyId={data.id}
                      doCompanyRefresh={() => {
                        fetchData();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RelatedSuggestions
                      companyId={data.id}
                      doCompanyRefresh={() => {
                        fetchData();
                      }}
                    />
                  </Grid>
                </Grid>
              );
            default:
              return null;
          }
        }}
      </AppTabs>
    </React.Fragment>
  );

  function getClassForChip(chipLabel: string) {
    if (
      chipLabel.indexOf('Lost') >= 0 ||
      chipLabel.indexOf('Trial (Expired)') >= 0
    ) {
      return classes.lostChip;
    } else if (
      chipLabel.indexOf('Active') >= 0 ||
      chipLabel.indexOf('Trial') >= 0
    ) {
      return classes.activeChip;
    } else if (chipLabel.indexOf('Warning') >= 0) {
      return classes.warningChip;
    } else if (chipLabel.indexOf('Ok') >= 0) {
      return classes.okChip;
    } else if (chipLabel.indexOf('Alert') >= 0) {
      return classes.alertChip;
    }
    return undefined;
  }
};

function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index;
}
