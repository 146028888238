import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface CurrentAllocations {
  zendesk_leader: string;
  IT: number;
  Generalist: number;
  'Finance & Legal': number;
  'No Industry': number;
}

interface AllocationDialogProps {
  open: boolean;
  allocations: CurrentAllocations[];
  onClose: () => void;
  loading?: boolean;
}

const AllocationDialog: React.FC<AllocationDialogProps> = ({
  open,
  allocations,
  onClose,
  loading,
}) => {
  const columnHeaders =
    allocations.length > 0 ? Object.keys(allocations[0]) : [];
  const totals = allocations.reduce(
    (acc, curr) => {
      return {
        IT: acc.IT + curr.IT,
        Generalist: acc.Generalist + curr.Generalist,
        'Finance & Legal': acc['Finance & Legal'] + curr['Finance & Legal'],
        'No Industry': acc['No Industry'] + curr['No Industry'],
      };
    },
    { IT: 0, Generalist: 0, 'Finance & Legal': 0, 'No Industry': 0 },
  );

  const mapHeaderToDisplayName = (header: string) => {
    const headerMap: { [key: string]: string } = {
      zendesk_leader: 'Agent',
      IT: 'IT & iGaming',
    };
    return headerMap[header] || header;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="allocation-dialog"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="allocation-dialog">Current Allocations</DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton variant="rect" height={200} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {columnHeaders.map((header) => (
                  <TableCell key={header}>
                    {mapHeaderToDisplayName(header)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allocations.map((allocation, index) => (
                <TableRow key={index}>
                  {columnHeaders.map((header) => (
                    <TableCell key={`${index}-${header}`}>
                      {allocation[header as keyof CurrentAllocations]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {totals.IT}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {totals.Generalist}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {totals['Finance & Legal']}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {totals['No Industry']}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllocationDialog;
