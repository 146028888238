import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { SnackbarContext } from 'components';
import { DateTimeFormat } from 'config';
import { format, parseISO } from 'date-fns';
import { usePrevious } from 'hooks';
import {
  addComment,
  addNps,
  fetchNpsComments,
  fetchNpsCompanies,
  fetchNpsDetails,
  fetchNpsLeaders,
  fetchNpsTalexioUsers,
  fetchTags,
  updateNps,
} from 'lib/Api/Nps';
import { getUserData } from 'lib/Helper/Auth';
import {
  NpsComment,
  NpsCompany,
  NpsLeader,
  NpsResponse,
  NpsTag,
  NpsTalexioUser,
} from 'lib/Model/Nps';
import * as React from 'react';
import { resolvePlatformNameFromPrefix } from 'views/Clients/components/SuggestedMatches';
import { NpsCommentBox } from './NpsCommentBox';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Send } from '@material-ui/icons';
import { fetchJobDetails } from 'lib/Api/Jobs';
import { Job } from 'lib/Model/Job';
import JobDetailsDialog from 'views/Clients/components/JobDetailsDialog';

const useStyles = makeStyles((theme) => ({
  radio: {
    padding: theme.spacing(0.25),
  },
  capitlizeText: {
    textTransform: 'capitalize',
  },
  label: {
    fontSize: '0.875rem',
  },
  disabledInput: {
    '& .Mui-disabled': {
      color: 'black',
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(11, minmax(0, 1fr))',
    gap: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      margin: 0,
      justifyContent: 'center',
      minWidth: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem',
    },
  },
  customFormControlLabel: {
    margin: '0 !important',
    padding: `${theme.spacing(0.25)}px !important`,
    '& .MuiRadio-root': {
      padding: theme.spacing(0.25),
    },
  },
  section: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(1),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '0.9rem',
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogContent: {
    padding: theme.spacing(2),
    maxHeight: '85vh',
  },
  rightColumn: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControlLabel-label': {
      whiteSpace: 'nowrap',
    },
  },
  commentSection: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > form': {
      marginTop: 'auto',
    },
    '& > div': {
      overflowY: 'auto',
      flexGrow: 1,
    },
  },
  denseInput: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  gridContainer: {
    margin: 0,
    width: '100%',
  },
  gridItem: {
    padding: theme.spacing(0.5),
  },
  headerSection: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.5),
    },
  },
  compactText: {
    fontSize: '0.9rem',
    lineHeight: 1.5,
  },
  noSubmissionRadio: {
    gridColumn: '1 / -1',
    justifyContent: 'flex-start !important',
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
  },
  checkboxGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    '& .MuiFormControlLabel-root': {
      margin: 0,
      marginRight: theme.spacing(2),
    },
    '& .MuiFormControlLabel-label': {
      whiteSpace: 'nowrap',
    },
  },
  compactTextfield: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        top: 0,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

interface Props {
  selectedNpsResponseId?: string;
  open: boolean;
  defaultCompanyId?: string;
  onClose(): void;
}

export const brandList = [
  {
    label: 'Konnekt',
    brand_identifier: 'knt',
  },
  {
    label: 'jobsinmalta',
    brand_identifier: 'jim',
  },
  {
    label: 'Payroll Malta',
    brand_identifier: 'pm',
  },
  {
    label: 'Salariesinmalta',
    brand_identifier: 'sim',
  },
  {
    label: 'Talexio',
    brand_identifier: 'tlx',
  },
];

const userTypes = [
  {
    label: 'Candidate',
    key: 'candidate',
    brand_identifier: 'knt',
  },
  {
    label: 'TCN Candidate',
    key: 'candidate-tcn',
    brand_identifier: 'knt',
  },
  {
    label: 'Executive Candidate',
    key: 'candidate-executive',
    brand_identifier: 'knt',
  },
  {
    label: 'Client',
    key: 'client',
    brand_identifier: 'knt',
  },
  {
    label: 'Admin',
    key: 'admin',
    brand_identifier: 'tlx',
  },
  {
    label: 'Non-Admin',
    key: 'non-admin',
    brand_identifier: 'tlx',
  },
  {
    label: 'SIM Client',
    key: 'client',
    brand_identifier: 'sim',
  },
  {
    label: 'PM Client',
    key: 'client',
    brand_identifier: 'pm',
  },
  {
    label: 'JIM Client',
    key: 'client',
    brand_identifier: 'jim',
  },
];

export const NpsDialog: React.FC<Props> = ({
  selectedNpsResponseId,
  defaultCompanyId,
  open,
  onClose,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();
  const [comment, setComment] = React.useState<string | undefined>();

  const [loadingNps, setLoadingNps] = React.useState(false);
  const [loadingComments, setLoadingComments] = React.useState(false);
  const [loadingTags, setLoadingTags] = React.useState(false);

  const [loadingCompanies, setLoadingCompanies] = React.useState(false);
  const [loadingNpsLeaders, setLoadingNpsLeaders] = React.useState(false);
  const [loadingTalexioUsers, setLoadingTalexioUsers] = React.useState(false);

  const [comments, setComments] = React.useState<NpsComment[]>([]);
  const [tags, setTags] = React.useState<NpsTag[]>([]);
  const [companies, setCompanies] = React.useState<NpsCompany[]>([]);
  const [npsLeaders, setNpsLeaders] = React.useState<NpsLeader[]>([]);
  const [talexioUsers, setTalexioUsers] = React.useState<NpsTalexioUser[]>([]);
  const [dateHandled, setdateHandled] = React.useState<Date | null>(null);
  const [isTtvSubmission, setTtvSubmission] = React.useState<boolean>(false);
  const [selectedTags, setSelectedTags] = React.useState<NpsTag[]>([]);

  const getDefaultNpsResponse = React.useCallback((): NpsResponse => {
    return {
      platform_nps_id: '',
      brand_identifier: brandList[0].brand_identifier,
      user_type: userTypes[0].key,
      company: '',
      company_id: defaultCompanyId, //TODO: this will probably not work unless we know the brand..
      date_sent: '',
      user_first_name: '',
      user_last_name: '',
      user_email: '',
      user_contact_number: '',
      rating: -1,
      tag_ids: '',
      tags: '',
      submission_type: '',
      is_ttv_submission: false,
    };
  }, [defaultCompanyId]);

  const [selectedNpsResponse, setSelectedNpsResponse] =
    React.useState<NpsResponse>(getDefaultNpsResponse());

  const prevOpen = usePrevious(open);
  const justOpened = !prevOpen && open;
  React.useEffect(() => {
    if (justOpened) {
      setComment('');
      setSelectedNpsResponse(getDefaultNpsResponse());
    }
  }, [justOpened, getDefaultNpsResponse]);

  const user = getUserData();
  const canEdit = user?.permissions?.includes('nps') || false;
  const [viewMoreDialogOpen, setViewMoreDialogOpen] = React.useState(false);
  const [selectedJob, setSelectedJob] = React.useState<Job[]>();
  const [dialogDataLoading, setDialogDataLoading] = React.useState(false);

  const handleViewMoreDialog = async (jobId: string) => {
    setDialogDataLoading(true);
    const jobDetails = await fetchJobDetails(jobId);
    setSelectedJob(jobDetails);
    setViewMoreDialogOpen(true);
    setDialogDataLoading(false);
  };

  const fetchComments = React.useCallback(() => {
    if (!selectedNpsResponseId) {
      return;
    }
    setLoadingComments(true);
    fetchNpsComments(selectedNpsResponseId).then((d) => {
      setLoadingComments(false);
      setComments(d);
    });
  }, [setLoadingComments, setComments, selectedNpsResponseId]);

  const fetchTagsData = React.useCallback(
    async (preselectedTagIds: string) => {
      setLoadingTags(true);
      const newTags = await fetchTags();
      setLoadingTags(false);
      setTags(newTags);

      if (!preselectedTagIds) {
        setSelectedTags([]);
        return;
      }

      const currentTagIds = preselectedTagIds.split(', ') ?? [];
      setSelectedTags(
        newTags.slice().filter((t) => currentTagIds.includes(t.id.toString())),
      );
    },
    [setLoadingTags, setTags],
  );

  const fetchCompanies = React.useCallback(() => {
    // Do not fetch companies if we do not have brand identifeir and if we are editing a record
    if (!selectedNpsResponse.brand_identifier || selectedNpsResponseId) {
      return;
    }

    setLoadingCompanies(true);
    fetchNpsCompanies(selectedNpsResponse.brand_identifier).then((d) => {
      setLoadingCompanies(false);
      setCompanies(d);
    });
  }, [
    setLoadingCompanies,
    setCompanies,
    selectedNpsResponse.brand_identifier,
    selectedNpsResponseId,
  ]);

  const fetchLeaders = React.useCallback(() => {
    setLoadingNpsLeaders(true);
    fetchNpsLeaders().then((d) => {
      setLoadingNpsLeaders(false);
      setNpsLeaders(d);
    });
  }, [setLoadingNpsLeaders, setNpsLeaders]);

  const fetchTalexioUsers = React.useCallback(() => {
    setLoadingTalexioUsers(true);
    fetchNpsTalexioUsers()
      .then((users) => {
        setTalexioUsers(users);
      })
      .finally(() => {
        setLoadingTalexioUsers(false);
      });
  }, []);

  const fetchNpsData = React.useCallback(() => {
    if (!open || prevOpen === open) {
      return;
    }
    if (!selectedNpsResponseId) {
      setSelectedNpsResponse(getDefaultNpsResponse());
      fetchTagsData('');
      fetchLeaders();
      fetchCompanies();
      setdateHandled(null);
      setTtvSubmission(false);
      return;
    }
    setLoadingNps(true);
    fetchNpsDetails(selectedNpsResponseId).then((d) => {
      fetchComments();
      fetchTagsData(d?.tag_ids ?? '');
      fetchLeaders();
      fetchCompanies();
      setLoadingNps(false);
      setSelectedNpsResponse(d);
      setdateHandled(d?.date_handled ? new Date(d.date_handled) : null);
      setTtvSubmission(d.is_ttv_submission || false);
    });
  }, [
    setLoadingNps,
    setSelectedNpsResponse,
    selectedNpsResponseId,
    getDefaultNpsResponse,
    open,
    prevOpen,
    fetchComments,
    fetchTagsData,
    fetchLeaders,
    fetchCompanies,
  ]);

  React.useEffect(() => {
    fetchNpsData();
  }, [fetchNpsData, selectedNpsResponseId]);

  React.useEffect(() => {
    if (selectedNpsResponseId) {
      return;
    }
    let newUserType = '';
    switch (selectedNpsResponse.brand_identifier) {
      case 'tlx':
        newUserType = 'admin';
        break;
      case 'knt':
      case 'pm':
      case 'jim':
      case 'sim':
        newUserType = 'client';
        break;
    }
    setSelectedNpsResponse((n) => ({
      ...n,
      user_type: newUserType,
      company_id: undefined, // clear selected company
    }));
  }, [
    selectedNpsResponse.brand_identifier,
    setSelectedNpsResponse,
    selectedNpsResponseId,
  ]);

  React.useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies, selectedNpsResponse.brand_identifier]);

  React.useEffect(() => {
    if (
      !selectedNpsResponseId &&
      selectedNpsResponse.brand_identifier === 'tlx'
    ) {
      fetchTalexioUsers();
    }
  }, [
    selectedNpsResponseId,
    selectedNpsResponse.brand_identifier,
    fetchTalexioUsers,
  ]);

  const loading = loadingComments || loadingTags || loadingNps;

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      onClose={() => onClose && onClose()}
    >
      <DialogContent className={classes.dialogContent}>
        {loadingNps ? (
          <Skeleton variant="rect" style={{ height: 400 }} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <div className={classes.headerSection}>
                <Typography variant="h5">
                  {selectedNpsResponseId ? 'Edit' : 'Create'} NPS Submission
                </Typography>
                {selectedNpsResponseId && (
                  <Chip
                    size="small"
                    color="primary"
                    label={
                      selectedNpsResponse?.submission_type?.startsWith('Manual')
                        ? 'Manual Submission'
                        : 'User Submission'
                    }
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      disabled={!canEdit}
                      checked={!!isTtvSubmission}
                      onChange={(e) => setTtvSubmission(e.target.checked)}
                    />
                  }
                  label="Team Voice"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      disabled={!canEdit}
                      checked={!!dateHandled}
                      onChange={(e, checked) =>
                        setdateHandled(checked ? new Date() : null)
                      }
                    />
                  }
                  label="Handled"
                />
              </div>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.section}>
                    <Typography
                      variant="subtitle2"
                      className={classes.sectionTitle}
                    >
                      Submission Details
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth>
                          {selectedNpsResponseId ? (
                            <React.Fragment>
                              <FormLabel>Brand</FormLabel>
                              <div className={classes.capitlizeText}>
                                {selectedNpsResponse.brand_identifier
                                  ? resolvePlatformNameFromPrefix(
                                      selectedNpsResponse.brand_identifier,
                                    )
                                  : 'N/A'}
                              </div>
                            </React.Fragment>
                          ) : (
                            <Autocomplete
                              options={brandList}
                              getOptionLabel={(o) => o.label}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  label="Brand"
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                              disableClearable={true}
                              value={brandList.find(
                                (b) =>
                                  b.brand_identifier ===
                                  selectedNpsResponse.brand_identifier,
                              )}
                              onChange={(e, newValue) => {
                                setSelectedNpsResponse((n) => ({
                                  ...n,
                                  brand_identifier: newValue.brand_identifier,
                                }));
                              }}
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth>
                          {selectedNpsResponseId ? (
                            <React.Fragment>
                              <FormLabel>User type</FormLabel>
                              <div className={classes.capitlizeText}>
                                {Boolean(selectedNpsResponse.user_type)
                                  ? selectedNpsResponse.user_type
                                  : 'N/A'}
                              </div>
                            </React.Fragment>
                          ) : (
                            <Autocomplete
                              options={userTypes}
                              getOptionLabel={(o) => o.label}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  label="User type"
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                              disableClearable={true}
                              value={userTypes.find(
                                (b) =>
                                  b.key === selectedNpsResponse.user_type &&
                                  b.brand_identifier ===
                                    selectedNpsResponse.brand_identifier,
                              )}
                              onChange={(e, newValue) => {
                                setSelectedNpsResponse((n) => ({
                                  ...n,
                                  user_type: newValue.key,
                                }));
                              }}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.section}>
                    <Typography
                      variant="subtitle2"
                      className={classes.sectionTitle}
                    >
                      Tags
                    </Typography>
                    <FormControl fullWidth className={classes.denseInput}>
                      <Autocomplete
                        options={tags}
                        getOptionSelected={(t1, t2) =>
                          t1.id.toString() === t2.id.toString()
                        }
                        getOptionLabel={(t) => t.tag_name}
                        loading={loadingTags}
                        disabled={loading || !canEdit}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            label="Tags"
                            {...params}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        multiple={true}
                        value={selectedTags}
                        onChange={(e, newTags) => {
                          setSelectedTags(newTags);
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.section}>
                    <Typography
                      variant="subtitle2"
                      className={classes.sectionTitle}
                    >
                      User Information
                    </Typography>
                    <Grid container spacing={1}>
                      {selectedNpsResponseId ? (
                        <React.Fragment>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={`${classes.formControl} ${classes.disabledInput}`}
                                fullWidth
                              >
                                <TextField
                                  className={classes.capitlizeText}
                                  value={selectedNpsResponse.user_first_name}
                                  variant="outlined"
                                  label="First Name"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  disabled={!canEdit}
                                  onChange={(e) => {
                                    const newFirstName = e.target.value;
                                    setSelectedNpsResponse((n) => ({
                                      ...n,
                                      user_first_name: newFirstName,
                                    }));
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={`${classes.formControl} ${classes.disabledInput}`}
                                fullWidth
                              >
                                <TextField
                                  className={classes.capitlizeText}
                                  value={selectedNpsResponse.user_last_name}
                                  variant="outlined"
                                  label="Last Name"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  disabled={!canEdit}
                                  onChange={(e) => {
                                    const newLastName = e.target.value;
                                    setSelectedNpsResponse((n) => ({
                                      ...n,
                                      user_last_name: newLastName,
                                    }));
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={`${classes.formControl} ${classes.disabledInput}`}
                                fullWidth
                              >
                                <TextField
                                  className={classes.capitlizeText}
                                  value={selectedNpsResponse.user_email}
                                  variant="outlined"
                                  label="Email"
                                  fullWidth
                                  type="email"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={!canEdit}
                                  onChange={(e) => {
                                    const newEmail = e.target.value;
                                    setSelectedNpsResponse((n) => ({
                                      ...n,
                                      user_email: newEmail,
                                    }));
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={`${classes.formControl} ${classes.disabledInput}`}
                                fullWidth
                              >
                                <TextField
                                  className={classes.capitlizeText}
                                  value={
                                    selectedNpsResponse.user_contact_number
                                  }
                                  variant="outlined"
                                  label="Contact number"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  disabled={!canEdit}
                                  onChange={(e) => {
                                    const newContactNumber = e.target.value;
                                    setSelectedNpsResponse((n) => ({
                                      ...n,
                                      user_contact_number: newContactNumber,
                                    }));
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>

                          {![
                            'candidate',
                            'candidate-tcn',
                            'candidate-executive',
                          ].includes(selectedNpsResponse.user_type ?? '') && (
                            <Grid item xs={12} sm={6}>
                              <FormLabel>Company</FormLabel>
                              <div className={classes.capitlizeText}>
                                {Boolean(selectedNpsResponse.company)
                                  ? selectedNpsResponse.company
                                  : 'N/A'}
                              </div>
                            </Grid>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {!selectedNpsResponseId &&
                            selectedNpsResponse.brand_identifier === 'tlx' &&
                            selectedNpsResponse.user_type === 'admin' && (
                              <Grid item xs={12}>
                                <FormControl
                                  className={classes.formControl}
                                  fullWidth
                                >
                                  <Autocomplete
                                    loading={loadingTalexioUsers}
                                    options={talexioUsers}
                                    getOptionLabel={(user) =>
                                      `${user.first_name} ${user.last_name} (${user.company})`
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Talexio User"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                    onChange={(_, user) => {
                                      if (user) {
                                        setSelectedNpsResponse((prev) => ({
                                          ...prev,
                                          user_first_name: user.first_name,
                                          user_last_name: user.last_name,
                                          user_email: user.email_address,
                                          user_contact_number:
                                            user.mobile_number,
                                          company: user.company,
                                          company_id: user.company_id,
                                        }));
                                      }
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                            )}

                          <Grid item xs={12} sm={6}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <TextField
                                fullWidth
                                value={selectedNpsResponse.user_first_name}
                                variant="outlined"
                                placeholder="First name"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setSelectedNpsResponse((n) => ({
                                    ...n,
                                    user_first_name: newValue,
                                  }));
                                }}
                                required
                                label={`User first name (${selectedNpsResponse.user_type})`}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <TextField
                                fullWidth
                                value={selectedNpsResponse.user_last_name}
                                variant="outlined"
                                placeholder="Last name"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setSelectedNpsResponse((n) => ({
                                    ...n,
                                    user_last_name: newValue,
                                  }));
                                }}
                                required
                                label={`User last name (${selectedNpsResponse.user_type})`}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <TextField
                                fullWidth
                                value={selectedNpsResponse.user_email}
                                variant="outlined"
                                placeholder="Email address"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setSelectedNpsResponse((n) => ({
                                    ...n,
                                    user_email: newValue,
                                  }));
                                }}
                                required
                                label={`User email (${selectedNpsResponse.user_type})`}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <TextField
                                fullWidth
                                value={selectedNpsResponse.user_contact_number}
                                variant="outlined"
                                placeholder="Contact number"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setSelectedNpsResponse((n) => ({
                                    ...n,
                                    user_contact_number: newValue,
                                  }));
                                }}
                                required
                                label={`Contact number (${selectedNpsResponse.user_type})`}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            {![
                              'candidate',
                              'candidate-tcn',
                              'candidate-executive',
                            ].includes(selectedNpsResponse.user_type ?? '') && (
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                              >
                                <Autocomplete
                                  loading={loadingCompanies}
                                  disabled={loadingCompanies}
                                  getOptionLabel={(o) => o.company_title}
                                  options={companies}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      label="Company"
                                      placeholder={
                                        loadingCompanies
                                          ? 'Loading...'
                                          : 'Company'
                                      }
                                      {...params}
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                  value={
                                    companies.find(
                                      (f) =>
                                        f.id === selectedNpsResponse.company_id,
                                    ) ?? null
                                  }
                                  onChange={(e, newValue) => {
                                    setSelectedNpsResponse((n) => ({
                                      ...n,
                                      company_id: newValue?.id,
                                      company: newValue?.company_title,
                                    }));
                                  }}
                                />
                              </FormControl>
                            )}
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.section}>
                    <Typography
                      variant="subtitle2"
                      className={classes.sectionTitle}
                    >
                      Company & Role Details
                    </Typography>
                    <Grid container spacing={1}>
                      {selectedNpsResponseId ? (
                        <React.Fragment>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <FormLabel>Designation</FormLabel>
                              <Typography variant="body1">
                                {Boolean(selectedNpsResponse.user_designation)
                                  ? selectedNpsResponse.user_designation
                                  : 'N/A'}
                              </Typography>
                            </FormControl>
                          </Grid>

                          {['knt', 'pm'].includes(
                            selectedNpsResponse.brand_identifier ?? '',
                          ) && (
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                              >
                                {selectedNpsResponseId ? (
                                  <React.Fragment>
                                    <FormLabel>Reviewee</FormLabel>
                                    <div>
                                      {Boolean(selectedNpsResponse.reviewee)
                                        ? selectedNpsResponse.reviewee
                                        : 'N/A'}{' '}
                                      {selectedNpsResponse.team_identifier
                                        ? `(${selectedNpsResponse.team_identifier})`
                                        : null}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <Autocomplete
                                    loading={loadingNpsLeaders}
                                    getOptionLabel={(o) =>
                                      `${o.reviewee}${
                                        o.reviewee !== 'All'
                                          ? ` (${o.team_identifier})`
                                          : ''
                                      }`
                                    }
                                    options={npsLeaders}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="outlined"
                                        label="Reviewee"
                                        {...params}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                    value={
                                      npsLeaders.find(
                                        (f) =>
                                          f.reviewee ===
                                            selectedNpsResponse.reviewee &&
                                          f.team_identifier ===
                                            selectedNpsResponse.team_identifier,
                                      ) ?? null
                                    }
                                    onChange={(e, newValue) => {
                                      setSelectedNpsResponse((n) => ({
                                        ...n,
                                        reviewee:
                                          newValue?.reviewee ?? undefined,
                                        team_identifier:
                                          newValue?.team_identifier ??
                                          undefined,
                                      }));
                                    }}
                                  />
                                )}
                              </FormControl>
                            </Grid>
                          )}

                          {selectedNpsResponse.platform_nps_id && (
                            <Grid item xs={12} sm={6}>
                              {selectedNpsResponse.user_type === 'candidate' &&
                                selectedNpsResponse.brand_identifier ===
                                  'knt' &&
                                selectedNpsResponse.user_id && (
                                  <Link
                                    href={`https://exp42.talexiohr.com/ats/candidates/${selectedNpsResponse.user_id}`}
                                    target="_blank"
                                    rel="noopener"
                                  >
                                    Candidate URL{' '}
                                    <OpenInNewIcon fontSize="small" />
                                  </Link>
                                )}
                              {selectedNpsResponse.user_type === 'client' &&
                                selectedNpsResponse.brand_identifier ===
                                  'knt' &&
                                selectedNpsResponse.product_id && (
                                  <Link
                                    onClick={() => {
                                      if (selectedNpsResponse.product_id) {
                                        handleViewMoreDialog(
                                          selectedNpsResponse.product_id,
                                        );
                                      }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Vacancy Details{' '}
                                    <OpenInNewIcon fontSize="small" />
                                  </Link>
                                )}
                            </Grid>
                          )}

                          {selectedNpsResponseId && (
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                              >
                                <FormLabel>Date submitted</FormLabel>
                                <div>
                                  {selectedNpsResponse.date_submitted
                                    ? format(
                                        parseISO(
                                          selectedNpsResponse.date_submitted,
                                        ),
                                        DateTimeFormat.LONG_FRIENDLY,
                                      )
                                    : 'Unsubmitted'}
                                </div>
                              </FormControl>
                            </Grid>
                          )}

                          {selectedNpsResponseId && (
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                              >
                                <FormLabel>Date sent</FormLabel>
                                <div>
                                  {selectedNpsResponse.date_sent &&
                                  selectedNpsResponse.date_sent !== 'NaT'
                                    ? format(
                                        parseISO(selectedNpsResponse.date_sent),
                                        DateTimeFormat.LONG_FRIENDLY,
                                      )
                                    : 'N/A'}
                                </div>
                              </FormControl>
                            </Grid>
                          )}
                        </React.Fragment>
                      ) : (
                        <div>No designation available</div>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={5}>
              <div className={classes.rightColumn}>
                <div className={classes.section}>
                  <Typography
                    variant="subtitle2"
                    className={classes.sectionTitle}
                  >
                    Rating
                  </Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      name="rating"
                      value={selectedNpsResponse.rating}
                      onChange={(e, value) => {
                        const newRating = parseInt(value, 10);
                        setSelectedNpsResponse((n) => ({
                          ...n,
                          rating: newRating,
                        }));
                      }}
                      className={classes.radioGroup}
                    >
                      {[...Array(11).keys()].map((s) => (
                        <FormControlLabel
                          className={classes.customFormControlLabel}
                          disabled={!canEdit}
                          key={s}
                          value={s}
                          control={
                            <Radio
                              className={classes.radio}
                              disabled={loading}
                            />
                          }
                          label={s.toString()}
                          classes={{ label: classes.label }}
                        />
                      ))}
                      <FormControlLabel
                        className={classes.noSubmissionRadio}
                        value={-1}
                        disabled={!canEdit}
                        control={
                          <Radio className={classes.radio} disabled={loading} />
                        }
                        label={'No submission'}
                        classes={{ label: classes.label }}
                        style={{ marginLeft: '5px' }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {selectedNpsResponse.platform_nps_id && (
                  <div className={classes.commentSection}>
                    <Typography
                      variant="subtitle2"
                      className={classes.sectionTitle}
                    >
                      Comments
                    </Typography>
                    {loadingComments ? (
                      <Skeleton variant="rect" style={{ height: 100 }} />
                    ) : (
                      comments.map((c, index) => (
                        <NpsCommentBox
                          comment={c}
                          selectedNpsResponse={selectedNpsResponse}
                          key={`comment_${index}`}
                          canEdit={canEdit}
                          fetchComments={fetchComments}
                          reviewees={npsLeaders}
                        />
                      ))
                    )}
                    <form
                      onSubmit={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!selectedNpsResponse.platform_nps_id || !comment) {
                          return false;
                        }

                        setLoadingComments(true);
                        try {
                          await addComment(
                            selectedNpsResponse.platform_nps_id,
                            comment,
                          );
                          setComment('');
                          fetchComments();
                        } catch (e) {
                          snackbar.error(
                            `Failed to add comment at this time: ${e}`,
                          );
                          setLoadingComments(false);
                        }
                        return false;
                      }}
                    >
                      {user?.permissions?.includes('nps') && (
                        <TextField
                          className={classes.compactTextfield}
                          fullWidth
                          variant="outlined"
                          placeholder="Type here to add your comment..."
                          onChange={(e) => setComment(e.currentTarget.value)}
                          value={comment}
                          autoFocus
                          required
                          multiline
                          helperText="Comments are saved automatically when added. You can add more detail such as the action date by clicking the downward arrow."
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                type="submit"
                                size="small"
                                disabled={loading}
                              >
                                <Send />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    </form>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        {canEdit && (
          <Button
            color="primary"
            onClick={async () => {
              setLoadingNps(true);
              try {
                if (selectedNpsResponse.platform_nps_id) {
                  const currentTagIds =
                    selectedNpsResponse.tag_ids?.split(', ') ?? [];
                  const addTags = selectedTags
                    .filter((t) => !currentTagIds.includes(t.id.toString()))
                    .map((t) => t.id.toString());
                  const removeTags = currentTagIds.filter(
                    (id) => !selectedTags.some((t) => t.id.toString() === id),
                  );
                  // this is an edit
                  await updateNps(
                    selectedNpsResponse.platform_nps_id,
                    selectedNpsResponse.rating ?? -1,
                    selectedNpsResponse.user_email ?? '',
                    selectedNpsResponse.user_first_name ?? '',
                    selectedNpsResponse.user_last_name ?? '',
                    selectedNpsResponse.user_contact_number ?? '',
                    selectedNpsResponse.company ?? '',
                    selectedNpsResponse.company_id ?? '',
                    selectedNpsResponse.user_type ?? '',
                    selectedNpsResponse.brand_identifier ?? '',
                    selectedNpsResponse.reviewee ?? '',
                    dateHandled ? dateHandled.toISOString() : '',
                    isTtvSubmission,
                    addTags,
                    removeTags,
                  );
                } else {
                  //this is a create
                  await addNps(
                    selectedNpsResponse.rating ?? -1,
                    selectedNpsResponse.user_email ?? '',
                    selectedNpsResponse.user_first_name ?? '',
                    selectedNpsResponse.user_last_name ?? '',
                    selectedNpsResponse.user_contact_number ?? '',
                    selectedNpsResponse.company ?? '',
                    selectedNpsResponse.company_id ?? '',
                    selectedNpsResponse.user_type ?? '',
                    selectedNpsResponse.brand_identifier ?? '',
                    selectedNpsResponse.reviewee ?? '',
                    selectedNpsResponse.is_ttv_submission || false,
                    selectedTags.map((t) => t.id),
                  );
                }
              } catch (e) {
                snackbar.error(`Failed to save NPS: ${e}`);
              }
              setLoadingNps(false);
              onClose && onClose();
            }}
            disabled={loading}
          >
            Save changes
          </Button>
        )}
        {onClose && (
          <Button onClick={() => onClose()} color="default" disabled={loading}>
            Cancel
          </Button>
        )}
      </DialogActions>
      <JobDetailsDialog
        open={viewMoreDialogOpen}
        job={selectedJob!}
        loading={dialogDataLoading}
        onClose={() => setViewMoreDialogOpen(false)}
      />
    </Dialog>
  );
};
