import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  makeStyles,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  CircularProgress,
  Link,
  IconButton,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {
  QuoteResponse,
  ModuleDiscount,
  QuoteResponseLineItem,
  PagedQuoteHistory,
  QuoteDetailItem,
} from 'lib/Model/QuoteGenerator';
import {
  generateQuote,
  saveQuote,
  fetchQuoteHistory,
  fetchQuoteDetails,
  updateQuoteCompany,
} from 'lib/Api/QuoteGenerator';
import { Alert, Autocomplete } from '@material-ui/lab';
import { fetchDriveCompanies } from 'lib/Api/Drive';
import { DriveCompany } from 'lib/Model/Drive';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import { useLocation } from 'react-router-dom';
import QuoteDetailsDialog from './components/QuoteDetailsDialog';
import { Warning } from '@material-ui/icons';
import QuoteGenerationDialog from './components/QuoteGenerationDialog';
import { AllocationCompany } from 'lib/Model/ClientAllocation';

// better just in case, to be a bit flexible with pricing.json
const CORE_MODULES = [
  { key: 'hr_essentials_leave', label: 'HR Essentials & Leave' },
  { key: 'payroll_leave_hr_essentials', label: 'Payroll Calculations' },
  { key: 'performance', label: 'Performance' },
  { key: 'time_and_attendance', label: 'Time & Attendance' },
  { key: 'training_management', label: 'Training Management' },
  { key: 'csp_per_payslip', label: 'CSP Payslips' },
];

const CORE_HARDWARE = [
  { key: 'uA760', label: 'UA760' },
  { key: 'silkBio', label: 'SilkBio 101tc' },
  {
    key: 'shared_team_virtual_punchclock',
    label: 'Shared Team Virtual Punchclock',
  },
  { key: 'rfid_em_cards', label: 'RFID EM Cards' },
];

const CORE_EXTRAS = [
  { key: 'data_migration', label: 'Data Migration' },
  { key: 'parallel_payroll_runs', label: 'Parallel Payroll Runs' },
  { key: 'signrequest', label: 'SignRequest' },
  { key: 'reporting_database', label: 'Reporting Database' },
  { key: 'onboarding_session', label: 'Onboarding Session' },
  { key: 'visitor_management', label: 'Visitor Management System' },
];

const CORE_ATS = [
  { key: 'admin_licence', label: 'HR Manager License' },
  { key: 'line_manager_licence', label: 'Hiring Manager License' },
];

const useStyles = makeStyles((theme) => ({
  stickyQuote: {
    position: 'sticky',
    top: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    overflowY: 'auto',
    zIndex: 1,
  },
  stickyContainer: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  quoteHistorySection: {
    position: 'relative',
    zIndex: 2,
  },
  table: {
    minWidth: 650,
  },
  discountText: {
    color: theme.palette.error.main,
  },
  searchBox: {
    padding: theme.spacing(1),
  },
  quoteFooter: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  finalizeButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  quoteHeaderActions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
    },
  },
  sectionTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontSize: '1.1rem',
  },
  sectionDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  formSection: {
    marginBottom: theme.spacing(1.5),
  },
  moduleRow: {
    marginBottom: theme.spacing(0.5),
  },
  moduleControls: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainCard: {
    padding: theme.spacing(2),
  },
  mainContainer: {
    padding: theme.spacing(2),
  },
  contentSection: {
    marginBottom: theme.spacing(3),
  },
  folderFoundMessage: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.success.main,
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
  folderNotFoundMessage: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
}));

const formatNumber = (num: number): string => {
  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const QuoteGeneratorView: React.FC = () => {
  const classes = useStyles();
  const [companySize, setCompanySize] = React.useState<number>(0);
  const [companyName, setCompanyName] = React.useState<string>('');
  const [numberOfCompanies, setNumberOfCompanies] = React.useState<number>(1);
  const [requestedBy, setRequestedBy] = React.useState<string>('');
  const [selectedModules, setSelectedModules] = React.useState<string[]>([]);
  const [selectedHardware, setSelectedHardware] = React.useState<string[]>([]);
  const [selectedExtras, setSelectedExtras] = React.useState<string[]>([]);
  const [selectedATS, setSelectedATS] = React.useState<string[]>([]);
  const [quoteResult, setQuoteResult] = React.useState<QuoteResponse | null>(
    null,
  );
  const [quoteTimestamp, setQuoteTimestamp] = React.useState<Date | null>(null);
  const [moduleUserQty, setModuleUserQty] = React.useState<
    Record<string, number>
  >({});
  const [selectedHardwareQty, setSelectedHardwareQty] = React.useState<
    Record<string, number>
  >({});
  const [moduleDiscounts, setModuleDiscounts] = React.useState<
    Record<string, ModuleDiscount>
  >({});
  const [selectedATSQty, setSelectedATSQty] = React.useState<
    Record<string, number>
  >({});
  const [extrasQty, setExtrasQty] = React.useState<Record<string, number>>({});
  const [extrasDiscounts, setExtrasDiscounts] = React.useState<
    Record<string, { discountSetupFee: number; discountUnitPrice?: number }>
  >({});
  const [isQuoteStale, setIsQuoteStale] = React.useState<boolean>(false);
  const hasGeneratedQuote = React.useRef(false);

  const [companies, setCompanies] = React.useState<
    DriveCompany[] | undefined
  >();
  const [loadingCompanies, setLoadingCompanies] = React.useState(false);
  const [selectedCompanyFromDropdown, setSelectedCompanyFromDropdown] =
    React.useState<DriveCompany | null>(null);
  const [useExistingCompany, setUseExistingCompany] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const [quoteHistory, setQuoteHistory] = React.useState<
    PagedQuoteHistory | undefined
  >();
  const [loadingHistory, setLoadingHistory] = React.useState(false);
  const [historyTextSearch, setHistoryTextSearch] = React.useState<
    string | undefined
  >();
  const debouncedHistoryTextSearch = useDebounce(historyTextSearch, 400);
  const [historyPageNumber, setHistoryPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = React.useState<number | null>(
    null,
  );
  const [quoteDetails, setQuoteDetails] = React.useState<QuoteDetailItem[]>([]);
  const [detailsLoading, setDetailsLoading] = React.useState(false);
  const [detailsError, setDetailsError] = React.useState<string | null>(null);

  const [showHrWarningDialog, setShowHrWarningDialog] =
    React.useState<boolean>(false);
  const [pendingAction, setPendingAction] = React.useState<
    'generate' | 'finalize' | null
  >(null);

  const [showSlidesDialog, setShowSlidesDialog] = React.useState(false);
  const [savedQuoteId, setSavedQuoteId] = React.useState<number | null>(null);

  const [associationDialogOpen, setAssociationDialogOpen] =
    React.useState(false);
  const [selectedQuoteForAssociation, setSelectedQuoteForAssociation] =
    React.useState<{
      id: number;
      companyName: string;
    } | null>(null);

  const [selectedCompanyForAssociation, setSelectedCompanyForAssociation] =
    React.useState<AllocationCompany | null>(null);
  const [updatingCompany, setUpdatingCompany] = React.useState(false);
  const [updateError, setUpdateError] = React.useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);

  React.useEffect(() => {
    setModuleUserQty((prev) => {
      const updated = { ...prev };
      selectedModules.forEach((moduleKey) => {
        if (
          moduleKey !== 'time_and_attendance' &&
          moduleKey !== 'csp_per_payslip'
        ) {
          updated[moduleKey] = companySize;
        }
      });
      return updated;
    });
  }, [companySize, selectedModules, selectedATS]);

  React.useEffect(() => {
    if (hasGeneratedQuote.current) {
      setIsQuoteStale(true);
    }
  }, [
    companySize,
    companyName,
    selectedModules,
    selectedHardware,
    selectedExtras,
    selectedATS,
    moduleUserQty,
    selectedHardwareQty,
    moduleDiscounts,
    selectedATSQty,
    extrasQty,
    extrasDiscounts,
  ]);

  const fetchCompanies = React.useCallback(() => {
    setLoadingCompanies(true);
    fetchDriveCompanies().then((d) => {
      setCompanies(d.data);
      setLoadingCompanies(false);
    });
  }, []);

  React.useEffect(() => {
    if (!loadingCompanies && companies === undefined) {
      fetchCompanies();
    }
  }, [fetchCompanies, loadingCompanies, companies]);

  React.useEffect(() => {
    if (selectedCompanyFromDropdown) {
      setCompanyName(selectedCompanyFromDropdown.company_title);
    }
  }, [selectedCompanyFromDropdown]);

  const handleModuleChange = (moduleKey: string) => {
    setSelectedModules((prev) => {
      const newModules = prev.includes(moduleKey)
        ? prev.filter((key) => key !== moduleKey)
        : [...prev, moduleKey];

      if (!newModules.includes(moduleKey)) {
        // we will remove module quantity and discount when unchecked
        setModuleUserQty((prev) => {
          const updated = { ...prev };
          delete updated[moduleKey];
          return updated;
        });
        setModuleDiscounts((prev) => {
          const updated = { ...prev };
          delete updated[moduleKey];
          return updated;
        });
      } else {
        // here we set default discount when checked
        setModuleDiscounts((prev) => ({
          ...prev,
          [moduleKey]: { discountUnitPrice: 0, discountSetupFee: 0 },
        }));
        if (
          moduleKey !== 'time_and_attendance' &&
          moduleKey !== 'csp_per_payslip'
        ) {
          setModuleUserQty((prev) => ({
            ...prev,
            [moduleKey]: companySize,
          }));
        }
      }

      return newModules;
    });
  };

  const handleHardwareChange = (hardwareKey: string) => {
    setSelectedHardware((prev) => {
      const isSelected = prev.includes(hardwareKey);
      const updated = isSelected
        ? prev.filter((key) => key !== hardwareKey)
        : [...prev, hardwareKey];
      if (isSelected) {
        setModuleDiscounts((prev) => {
          const updatedDiscounts = { ...prev };
          delete updatedDiscounts[hardwareKey];
          return updatedDiscounts;
        });
      } else {
        // set default discount when hardware is checked
        setModuleDiscounts((prev) => ({
          ...prev,
          [hardwareKey]: { discountUnitPrice: 0, discountSetupFee: 0 },
        }));
      }
      return updated;
    });
    setSelectedHardwareQty((prev) => {
      const updated = { ...prev };
      if (updated[hardwareKey] !== undefined) {
        delete updated[hardwareKey];
      } else {
        updated[hardwareKey] = 1;
      }
      return updated;
    });
  };

  const handleExtrasChange = (extrasKey: string) => {
    setSelectedExtras((prev) => {
      const newExtras = prev.includes(extrasKey)
        ? prev.filter((key) => key !== extrasKey)
        : [...prev, extrasKey];

      if (!newExtras.includes(extrasKey)) {
        setExtrasQty((prev) => {
          const updated = { ...prev };
          delete updated[extrasKey];
          return updated;
        });
        setExtrasDiscounts((prev) => {
          const updated = { ...prev };
          delete updated[extrasKey];
          return updated;
        });
      } else {
        setExtrasDiscounts((prev) => ({
          ...prev,
          [extrasKey]: {
            discountSetupFee: 0,
            discountUnitPrice: 0,
          },
        }));
      }

      return newExtras;
    });
  };

  const handleATSChange = (atsKey: string) => {
    setSelectedATS((prev) => {
      const isSelected = prev.includes(atsKey);
      const updated = isSelected
        ? prev.filter((key) => key !== atsKey)
        : [...prev, atsKey];
      if (isSelected) {
        setSelectedATSQty((prevQty) => {
          const updatedQty = { ...prevQty };
          delete updatedQty[atsKey];
          return updatedQty;
        });
      }
      return updated;
    });
  };

  const shouldShowHrEssentialsWarning = () => {
    const hasOtherCoreModules = selectedModules.some(
      (module) => module !== 'hr_essentials_leave',
    );
    const hasHrEssentials = selectedModules.includes('hr_essentials_leave');

    return hasOtherCoreModules && !hasHrEssentials;
  };

  const handleGenerateQuoteWithCheck = () => {
    if (shouldShowHrEssentialsWarning()) {
      setPendingAction('generate');
      setShowHrWarningDialog(true);
    } else {
      handleGenerateQuote();
    }
  };

  const handleFinaliseQuoteWithCheck = async () => {
    if (shouldShowHrEssentialsWarning()) {
      setPendingAction('finalize');
      setShowHrWarningDialog(true);
    } else {
      handleFinaliseQuote();
    }
  };

  const handleGenerateQuote = () => {
    const moduleSelections = selectedModules.map((key) => ({
      moduleKey: key,
      userQty: moduleUserQty[key] || companySize,
      ...(moduleDiscounts[key] ? moduleDiscounts[key] : {}),
    }));
    const hardwareSelections = selectedHardware.map((key) => ({
      moduleKey: key,
      userQty: selectedHardwareQty[key] || 1,
      discountUnitPrice: moduleDiscounts[key]?.discountUnitPrice || 0,
    }));
    const extraSelections = selectedExtras.map((key) => ({
      moduleKey: key,
      userQty:
        key === 'signrequest' ||
        key === 'reporting_database' ||
        key === 'parallel_payroll_runs' ||
        key === 'onboarding_session' ||
        key === 'data_migration' ||
        key === 'visitor_management'
          ? extrasQty[key] || 0
          : undefined,
      discountSetupFee: extrasDiscounts[key]?.discountSetupFee || 0,
      discountUnitPrice: extrasDiscounts[key]?.discountUnitPrice || 0,
    }));
    const atsSelections = selectedATS.map((atsKey) => ({
      moduleKey: atsKey,
      userQty: selectedATSQty[atsKey] || companySize,
      discountUnitPrice: moduleDiscounts[atsKey]?.discountUnitPrice || 0,
      discountSetupFee: moduleDiscounts[atsKey]?.discountSetupFee || 0,
    }));
    const allSelections = [
      ...moduleSelections,
      ...hardwareSelections,
      ...extraSelections,
      ...atsSelections,
    ];

    const quote = generateQuote({
      companyName,
      companySize,
      companyId: selectedCompanyFromDropdown?.id || null,
      numberOfCompanies,
      requestedBy,
      modules: allSelections,
    });
    setQuoteResult(quote);
    setQuoteTimestamp(new Date());
    setIsQuoteStale(false);
    hasGeneratedQuote.current = true;
  };

  const clearEverything = () => {
    setCompanyName('');
    setCompanySize(0);
    setSelectedModules([]);
    setSelectedHardware([]);
    setSelectedExtras([]);
    setSelectedATS([]);
    setModuleUserQty({});
    setSelectedHardwareQty({});
    setQuoteResult(null);
    setQuoteTimestamp(null);
    setSelectedCompanyFromDropdown(null);
    setUseExistingCompany(false);
    hasGeneratedQuote.current = false;
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const getModuleName = (key: string): string => {
    const module = CORE_MODULES.find((m) => m.key === key);
    if (module) return module.label;
    const hardware = CORE_HARDWARE.find((h) => h.key === key);
    if (hardware) return hardware.label;
    const extra = CORE_EXTRAS.find((e) => e.key === key);
    if (extra) return extra.label;
    const ats = CORE_ATS.find((a) => a.key === key);
    if (ats) return ats.label;
    return key;
  };

  const handleFinaliseQuote = async () => {
    if (!quoteResult) return;

    try {
      setIsSaving(true);
      const result = await saveQuote(quoteResult);
      setSavedQuoteId(result.quote_id);
      fetchQuoteHistoryData();

      setShowSlidesDialog(true);
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  const fetchQuoteHistoryData = React.useCallback(() => {
    setLoadingHistory(true);
    fetchQuoteHistory(
      historyPageNumber + 1,
      tableSettings.rowsPerPage,
      debouncedHistoryTextSearch,
    )
      .then((d) => {
        setLoadingHistory(false);
        setQuoteHistory(d);
      })
      .catch((error) => {
        setLoadingHistory(false);
      });
  }, [
    historyPageNumber,
    tableSettings.rowsPerPage,
    debouncedHistoryTextSearch,
  ]);

  React.useEffect(() => {
    fetchQuoteHistoryData();
  }, [fetchQuoteHistoryData]);

  const handleViewDetails = async (
    quoteId: number,
    companyId: string | null,
  ) => {
    setSelectedQuoteId(quoteId);
    setDetailsDialogOpen(true);
    setDetailsLoading(true);
    setDetailsError(null);

    try {
      const data = await fetchQuoteDetails(quoteId);
      setQuoteDetails(data);
    } catch (error) {
      setDetailsError(
        `Failed to load proposal details: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      );
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleOpenAssociationDialog = (
    quoteId: number,
    companyName: string,
  ) => {
    setSelectedQuoteForAssociation({ id: quoteId, companyName });
    setAssociationDialogOpen(true);
    setUpdateSuccess(false);
    setUpdateError(null);
    setSelectedCompanyForAssociation(null);
  };

  const handleCloseAssociationDialog = () => {
    setAssociationDialogOpen(false);
    setSelectedQuoteForAssociation(null);
    setUpdateSuccess(false);
    setUpdateError(null);
  };

  const handleUpdateCompany = async () => {
    if (!selectedCompanyForAssociation || !selectedQuoteForAssociation) return;

    setUpdatingCompany(true);
    setUpdateError(null);
    setUpdateSuccess(false);

    try {
      await updateQuoteCompany(
        selectedQuoteForAssociation.id,
        selectedCompanyForAssociation.id,
        selectedCompanyForAssociation.company_title,
      );
      setUpdateSuccess(true);
      fetchQuoteHistoryData();
    } catch (error) {
      setUpdateError(
        `Failed to update proposal: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      );
    } finally {
      setUpdatingCompany(false);
    }
  };

  const historyColumns: PageableTableHeader[] = [
    {
      key: 'date',
      label: 'Created On',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'company_name',
      label: 'Company Name',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'items',
      label: 'Items',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'created_by',
      label: 'Created By',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'details',
      label: 'Details',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'Link',
      label: 'Link',
      sortable: false,
      props: { align: 'center' },
    },
  ];

  const refreshQuoteHistory = () => {
    fetchQuoteHistoryData();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Talexio Proposal Generator"
            subheader="Generate proposals for clients."
          />
          <Divider />
          <CardContent className={classes.mainContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  This generator is currently in beta. It is recommended to
                  cross check results against this{' '}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1B5DIdp9ibjsx9oLDZ1oVGCHzS-bHIQUMkDyKHnyrTKo/edit?gid=1523236729#gid=1523236729"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this sheet.
                  </a>
                </Alert>
              </Grid>

              <Grid item xs={12} className={classes.contentSection}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Company Information
                </Typography>
                <Divider className={classes.sectionDivider} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={useExistingCompany}
                          onChange={(e) => {
                            setUseExistingCompany(e.target.checked);
                            if (!e.target.checked) {
                              setSelectedCompanyFromDropdown(null);
                            }
                            setCompanyName('');
                          }}
                        />
                      }
                      label="Select from existing companies"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {useExistingCompany ? (
                      <>
                        <Autocomplete
                          getOptionLabel={(c) => c.company_title}
                          options={companies ?? []}
                          loading={loadingCompanies}
                          value={selectedCompanyFromDropdown}
                          onChange={(e, newValue) => {
                            setSelectedCompanyFromDropdown(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Company Name"
                              fullWidth
                              autoFocus
                              placeholder="Start typing to search for a company"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {selectedCompanyFromDropdown && (
                          <Typography
                            variant="caption"
                            className={
                              selectedCompanyFromDropdown.proposals_folder_id
                                ? classes.folderFoundMessage
                                : classes.folderNotFoundMessage
                            }
                          >
                            {selectedCompanyFromDropdown.proposals_folder_id ? (
                              <>
                                <CheckCircleIcon fontSize="small" />
                                Drive folder found. Proposal can be
                                automatically created in this folder.
                              </>
                            ) : (
                              <>
                                <Warning fontSize="small" />
                                Couldn't automatically find the Drive folder for
                                this client. This folder may not exist, or the
                                folder ID is missing on Zendesk. You'll need to
                                manually pick the folder if you want to save the
                                proposal there, or download the proposal and
                                upload it to the correct folder later.
                              </>
                            )}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <TextField
                        fullWidth
                        label="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Company Size"
                      value={companySize || ''}
                      onChange={(e) =>
                        setCompanySize(parseInt(e.target.value, 10))
                      }
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Number of Companies"
                      value={numberOfCompanies}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        setNumberOfCompanies(value > 0 ? value : 1);
                      }}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Requested By"
                      value={requestedBy}
                      onChange={(e) => setRequestedBy(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Optional"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.contentSection}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Modules
                </Typography>
                <Divider className={classes.sectionDivider} />

                <FormControl component="fieldset" fullWidth>
                  <FormGroup>
                    {CORE_MODULES.map((module) => (
                      <Box key={module.key} className={classes.moduleRow}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedModules.includes(module.key)}
                              onChange={() => handleModuleChange(module.key)}
                            />
                          }
                          label={module.label}
                        />
                        {selectedModules.includes(module.key) && (
                          <Grid
                            container
                            spacing={2}
                            className={classes.moduleControls}
                          >
                            {(module.key === 'time_and_attendance' ||
                              module.key === 'csp_per_payslip') && (
                              <Grid item>
                                <TextField
                                  label={
                                    module.key === 'csp_per_payslip'
                                      ? 'Number of Payslips'
                                      : 'Number of Users'
                                  }
                                  value={moduleUserQty[module.key] ?? ''}
                                  onChange={(e) =>
                                    setModuleUserQty((prev) => ({
                                      ...prev,
                                      [module.key]: parseInt(
                                        e.target.value,
                                        10,
                                      ),
                                    }))
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                />
                              </Grid>
                            )}
                            <Grid item>
                              <TextField
                                label="Discount Unit Price"
                                type="number"
                                value={
                                  moduleDiscounts[module.key]
                                    ?.discountUnitPrice || 0
                                }
                                onChange={(e) =>
                                  setModuleDiscounts((prev) => ({
                                    ...prev,
                                    [module.key]: {
                                      ...prev[module.key],
                                      discountUnitPrice:
                                        parseFloat(e.target.value) || 0,
                                      discountSetupFee:
                                        prev[module.key]?.discountSetupFee || 0,
                                    },
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label="Discount Setup Fee"
                                type="number"
                                value={
                                  moduleDiscounts[module.key]
                                    ?.discountSetupFee || 0
                                }
                                onChange={(e) =>
                                  setModuleDiscounts((prev) => ({
                                    ...prev,
                                    [module.key]: {
                                      ...prev[module.key],
                                      discountSetupFee:
                                        parseFloat(e.target.value) || 0,
                                      discountUnitPrice:
                                        prev[module.key]?.discountUnitPrice ||
                                        0,
                                    },
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} className={classes.contentSection}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  ATS
                </Typography>
                <Divider className={classes.sectionDivider} />

                <FormControl component="fieldset" fullWidth>
                  <FormGroup>
                    {CORE_ATS.map((ats) => (
                      <Box key={ats.key} className={classes.moduleRow}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedATS.includes(ats.key)}
                              onChange={() => handleATSChange(ats.key)}
                            />
                          }
                          label={ats.label}
                        />
                        {selectedATS.includes(ats.key) && (
                          <Grid
                            container
                            spacing={2}
                            className={classes.moduleControls}
                          >
                            <Grid item>
                              <TextField
                                label="Number of Users"
                                value={selectedATSQty[ats.key] ?? ''}
                                onChange={(e) =>
                                  setSelectedATSQty((prev) => ({
                                    ...prev,
                                    [ats.key]: parseInt(e.target.value, 10),
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label="Discount Unit Price"
                                type="number"
                                value={
                                  moduleDiscounts[ats.key]?.discountUnitPrice ||
                                  0
                                }
                                onChange={(e) =>
                                  setModuleDiscounts((prev) => ({
                                    ...prev,
                                    [ats.key]: {
                                      ...prev[ats.key],
                                      discountUnitPrice:
                                        parseFloat(e.target.value) || 0,
                                      discountSetupFee:
                                        ats.key === 'line_manager_licence'
                                          ? 0
                                          : prev[ats.key]?.discountSetupFee ||
                                            0,
                                    },
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            {ats.key !== 'line_manager_licence' && (
                              <Grid item>
                                <TextField
                                  label="Discount Setup Fee"
                                  type="number"
                                  value={
                                    moduleDiscounts[ats.key]
                                      ?.discountSetupFee || 0
                                  }
                                  onChange={(e) =>
                                    setModuleDiscounts((prev) => ({
                                      ...prev,
                                      [ats.key]: {
                                        ...prev[ats.key],
                                        discountSetupFee:
                                          parseFloat(e.target.value) || 0,
                                        discountUnitPrice:
                                          prev[ats.key]?.discountUnitPrice || 0,
                                      },
                                    }))
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} className={classes.contentSection}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Hardware
                </Typography>
                <Divider className={classes.sectionDivider} />

                <FormControl component="fieldset" fullWidth>
                  <FormGroup>
                    {CORE_HARDWARE.map((hardware) => (
                      <Box key={hardware.key} className={classes.moduleRow}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedHardware.includes(hardware.key)}
                              onChange={() =>
                                handleHardwareChange(hardware.key)
                              }
                            />
                          }
                          label={hardware.label}
                        />
                        {selectedHardware.includes(hardware.key) && (
                          <Grid
                            container
                            spacing={2}
                            className={classes.moduleControls}
                          >
                            <Grid item>
                              <TextField
                                label="Quantity"
                                value={selectedHardwareQty[hardware.key] || 0}
                                onChange={(e) =>
                                  setSelectedHardwareQty((prev) => ({
                                    ...prev,
                                    [hardware.key]: parseInt(
                                      e.target.value,
                                      10,
                                    ),
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                label="Discount Unit Price"
                                type="number"
                                value={
                                  moduleDiscounts[hardware.key]
                                    ?.discountUnitPrice || 0
                                }
                                onChange={(e) =>
                                  setModuleDiscounts((prev) => ({
                                    ...prev,
                                    [hardware.key]: {
                                      discountUnitPrice:
                                        parseFloat(e.target.value) || 0,
                                      discountSetupFee: 0,
                                    },
                                  }))
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} className={classes.contentSection}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Extras
                </Typography>
                <Divider className={classes.sectionDivider} />

                <FormControl component="fieldset" fullWidth>
                  <FormGroup>
                    {CORE_EXTRAS.map((extra) => (
                      <Box key={extra.key} className={classes.moduleRow}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedExtras.includes(extra.key)}
                              onChange={() => handleExtrasChange(extra.key)}
                            />
                          }
                          label={extra.label}
                        />
                        {selectedExtras.includes(extra.key) && (
                          <Grid
                            container
                            spacing={2}
                            className={classes.moduleControls}
                          >
                            {(extra.key === 'signrequest' ||
                              extra.key === 'reporting_database' ||
                              extra.key === 'parallel_payroll_runs' ||
                              extra.key === 'data_migration' ||
                              extra.key === 'visitor_management' ||
                              extra.key === 'onboarding_session') && (
                              <Grid item>
                                <TextField
                                  label={
                                    extra.key === 'reporting_database'
                                      ? 'Number of Databases'
                                      : extra.key === 'parallel_payroll_runs'
                                      ? 'Number of Runs'
                                      : extra.key === 'data_migration'
                                      ? 'Number of Migrations'
                                      : extra.key === 'visitor_management'
                                      ? 'Number of Systems'
                                      : extra.key === 'onboarding_session'
                                      ? 'Number of Sessions'
                                      : 'Number of Users'
                                  }
                                  value={extrasQty[extra.key] || ''}
                                  onChange={(e) =>
                                    setExtrasQty((prev) => ({
                                      ...prev,
                                      [extra.key]: parseInt(e.target.value, 10),
                                    }))
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                />
                              </Grid>
                            )}
                            {(extra.key === 'signrequest' ||
                              extra.key === 'reporting_database' ||
                              extra.key === 'visitor_management') && (
                              <Grid item>
                                <TextField
                                  label="Discount Unit Price"
                                  type="number"
                                  value={
                                    extrasDiscounts[extra.key]
                                      ?.discountUnitPrice || 0
                                  }
                                  onChange={(e) =>
                                    setExtrasDiscounts((prev) => ({
                                      ...prev,
                                      [extra.key]: {
                                        ...prev[extra.key],
                                        discountUnitPrice:
                                          parseFloat(e.target.value) || 0,
                                        discountSetupFee:
                                          prev[extra.key]?.discountSetupFee ||
                                          0,
                                      },
                                    }))
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            )}
                            {(extra.key === 'data_migration' ||
                              extra.key === 'parallel_payroll_runs' ||
                              extra.key === 'onboarding_session') && (
                              <Grid item>
                                <TextField
                                  label="Discount Setup Fee"
                                  type="number"
                                  value={
                                    extrasDiscounts[extra.key]
                                      ?.discountSetupFee || 0
                                  }
                                  onChange={(e) =>
                                    setExtrasDiscounts((prev) => ({
                                      ...prev,
                                      [extra.key]: {
                                        ...prev[extra.key],
                                        discountSetupFee:
                                          parseFloat(e.target.value) || 0,
                                      },
                                    }))
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.sectionDivider} />
                <Box display="flex" alignItems="center" gridGap={16} mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateQuoteWithCheck}
                    disabled={
                      !companyName ||
                      companySize <= 0 ||
                      numberOfCompanies <= 0 ||
                      (selectedModules.length === 0 &&
                        selectedATS.length === 0 &&
                        selectedHardware.length === 0 &&
                        selectedExtras.length === 0) ||
                      selectedATS.some(
                        (atsKey) =>
                          !selectedATSQty[atsKey] ||
                          selectedATSQty[atsKey] <= 0,
                      )
                    }
                  >
                    Preview Proposal
                  </Button>
                  <Button onClick={clearEverything} variant="outlined">
                    Clear
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {quoteResult && (
        <Grid item xs={12} md={6} className={classes.stickyContainer}>
          <div className={classes.stickyQuote}>
            <Card>
              <CardHeader
                title="Proposal Summary"
                subheader={`Generated proposal for ${quoteResult.companyName}${
                  quoteTimestamp ? ` at ${formatTime(quoteTimestamp)}` : ''
                }${
                  quoteResult.numberOfCompanies &&
                  quoteResult.numberOfCompanies > 1
                    ? ` (${quoteResult.numberOfCompanies} companies)`
                    : ''
                }${
                  quoteResult.requestedBy
                    ? ` - Requested by ${quoteResult.requestedBy}`
                    : ''
                }`}
                action={
                  <Box className={classes.quoteHeaderActions}>
                    {isQuoteStale && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGenerateQuote}
                        size="small"
                      >
                        Regenerate
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleFinaliseQuoteWithCheck}
                      disabled={isSaving || isQuoteStale}
                      startIcon={
                        isSaving ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isSaving ? 'Loading...' : 'Proceed to Finalise'}
                    </Button>
                  </Box>
                }
              />
              <Divider />
              <CardContent>
                {isQuoteStale && (
                  <Box mb={2}>
                    <Alert severity="warning">
                      Detected some changes, please regenerate proposal using
                      the button above.
                    </Alert>
                  </Box>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <Box component="span" fontWeight="fontWeightBold">
                        One-time Fee:
                      </Box>{' '}
                      €{formatNumber(quoteResult.oneTimeFee)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <Box component="span" fontWeight="fontWeightBold">
                        Unit Price:
                      </Box>{' '}
                      €{formatNumber(quoteResult.totalUnitPrice)}
                      {quoteResult.atsUnitPrice > 0 && (
                        <span>
                          {' '}
                          (+ €{formatNumber(quoteResult.atsUnitPrice)} ATS)
                        </span>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <Box component="span" fontWeight="fontWeightBold">
                        Monthly Fee:
                      </Box>{' '}
                      €{formatNumber(quoteResult.monthlyFee)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <Box component="span" fontWeight="fontWeightBold">
                        Annual Fee:
                      </Box>{' '}
                      €{formatNumber(quoteResult.annualFee)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="quote details"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Module Name</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">
                              Original Unit Price
                            </TableCell>
                            <TableCell align="center">
                              Discount Unit Price
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: '#f5f5f5',
                                fontWeight: 'bold',
                              }}
                            >
                              Final Unit Price
                            </TableCell>
                            <TableCell align="center">
                              Original Setup Fee
                            </TableCell>
                            <TableCell align="center">
                              Discount Setup Fee
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: '#f5f5f5',
                                fontWeight: 'bold',
                              }}
                            >
                              Final Setup Fee
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[
                            ...quoteResult.modules,
                            ...quoteResult.hardware,
                            ...quoteResult.extras,
                          ].map((item: QuoteResponseLineItem) => (
                            <TableRow key={item.moduleKey}>
                              <TableCell component="th" scope="row">
                                {getModuleName(item.moduleKey)}
                              </TableCell>
                              <TableCell align="center">
                                {item.userQty}
                              </TableCell>
                              <TableCell align="center">
                                {item.originalPrice
                                  ? formatNumber(item.originalPrice)
                                  : '-'}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.discountText}
                              >
                                {item.discountUnitPrice
                                  ? `-${formatNumber(item.discountUnitPrice)}`
                                  : '-'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor: '#f5f5f5',
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatNumber(
                                  (item.originalPrice ?? 0) -
                                    (item.discountUnitPrice ?? 0),
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {item.originalSetupFee
                                  ? formatNumber(item.originalSetupFee)
                                  : '-'}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.discountText}
                              >
                                {item.discountSetupFee
                                  ? `-${formatNumber(item.discountSetupFee)}`
                                  : '-'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor: '#f5f5f5',
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatNumber(
                                  (item.originalSetupFee ?? 0) -
                                    (item.discountSetupFee ?? 0),
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      )}

      <Grid item xs={12} className={classes.quoteHistorySection}>
        <Card>
          <CardHeader
            title="Proposal History"
            subheader="A history of all proposals generated through the system."
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="Type here to search for a company."
                value={historyTextSearch}
                className={classes.searchBox}
                onChange={(e) => setHistoryTextSearch(e.target.value ?? '')}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={false}
            rows={
              quoteHistory?.data?.map((quote, index) => ({
                key: index.toString(),
                cells: [
                  {
                    key: 'date',
                    display: quote.created_on && (
                      <DateDisplay date={quote.created_on} />
                    ),
                    props: { align: 'center' },
                  },
                  {
                    key: 'company_name',
                    display: quote.company_id ? (
                      <Link
                        href={`${window.location.origin}/clients/${quote.company_id}#productstack`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        {quote.company_name}
                      </Link>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        {quote.company_name}
                        <Tooltip title="This proposal is not properly linked with a company. Link them to make the proposal visible on Product Stack.">
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenAssociationDialog(
                                quote.quote_id,
                                quote.company_name,
                              )
                            }
                          >
                            <LinkOffIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                    props: { align: 'center' },
                  },
                  {
                    key: 'items',
                    display: quote.items
                      .split(',')
                      .map((item) =>
                        item
                          .trim()
                          .replace(/_/g, ' ')
                          .replace(/\b(\w)/g, (m) => m.toUpperCase())
                          .replace(/\bHr\b/g, 'HR')
                          .replace(/\bCsp\b/g, 'CSP')
                          .replace(/\bRfid\b/g, 'RFID')
                          .replace(/\bEm\b/g, 'EM'),
                      )
                      .join(', '),
                    props: { align: 'center' },
                  },
                  {
                    key: 'created_by',
                    display: quote.created_by,
                    props: { align: 'center' },
                  },
                  {
                    key: 'details',
                    display: (
                      <IconButton
                        onClick={() =>
                          handleViewDetails(quote.quote_id, quote.company_id)
                        }
                        size="small"
                        title="View Proposal Details"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ),
                    props: { align: 'center' },
                  },
                  {
                    key: 'Link',
                    display: quote.quote_slide_id ? (
                      <Link
                        href={`https://docs.google.com/presentation/d/${quote.quote_slide_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        View
                      </Link>
                    ) : (
                      '-'
                    ),
                    props: { align: 'center' },
                  },
                ],
              })) ?? []
            }
            loading={loadingHistory}
            pageNumber={historyPageNumber}
            rowCount={quoteHistory?.total_count ?? 0}
            columns={historyColumns}
            onChangePage={setHistoryPageNumber}
          />
        </Card>
      </Grid>

      {selectedQuoteId && (
        <QuoteDetailsDialog
          open={detailsDialogOpen}
          quoteDetails={quoteDetails}
          loading={detailsLoading}
          error={detailsError}
          onClose={() => setDetailsDialogOpen(false)}
          quoteId={selectedQuoteId}
          companyId={
            quoteHistory?.data?.find((q) => q.quote_id === selectedQuoteId)
              ?.company_id ?? null
          }
          companies={companies}
          loadingCompanies={loadingCompanies}
          onUpdateSuccess={refreshQuoteHistory}
          quoteSlideId={
            quoteHistory?.data?.find((q) => q.quote_id === selectedQuoteId)
              ?.quote_slide_id
          }
        />
      )}

      <Dialog
        open={showHrWarningDialog}
        onClose={() => setShowHrWarningDialog(false)}
        aria-labelledby="hr-warning-dialog-title"
      >
        <DialogTitle id="hr-warning-dialog-title">
          Module Recommendation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It's recommended to select the 'HR Essentials & Leave' module when
            using other core modules. Core modules should not be sold
            independently of the 'HR Essentials & Leave' module. Do you want to
            continue anyway?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowHrWarningDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowHrWarningDialog(false);
              if (pendingAction === 'generate') {
                handleGenerateQuote();
              } else if (pendingAction === 'finalize') {
                handleFinaliseQuote();
              }
              setPendingAction(null);
            }}
            color="primary"
            autoFocus
          >
            Continue Anyway
          </Button>
        </DialogActions>
      </Dialog>

      <QuoteGenerationDialog
        open={showSlidesDialog}
        quoteId={savedQuoteId}
        companyName={companyName}
        onClose={() => setShowSlidesDialog(false)}
        onSuccess={refreshQuoteHistory}
      />

      {/* Company Association Dialog */}
      <Dialog
        open={associationDialogOpen}
        onClose={handleCloseAssociationDialog}
        aria-labelledby="company-association-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="company-association-dialog-title">
          Link Proposal to Company
        </DialogTitle>
        <DialogContent>
          {updateSuccess ? (
            <Alert severity="success" variant="filled">
              Company updated successfully!
            </Alert>
          ) : (
            <>
              <Typography gutterBottom>
                This proposal{' '}
                <strong>"{selectedQuoteForAssociation?.companyName}"</strong> is
                not properly linked to a company in the system. This means it
                won't appear in the Product Stack section of a company. You can
                link it to an existing company below.
              </Typography>

              {updateError && (
                <Alert
                  severity="error"
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                >
                  {updateError}
                </Alert>
              )}

              <Box mt={3} mb={3}>
                <Autocomplete
                  getOptionLabel={(c) => c.company_title}
                  options={companies ?? []}
                  loading={loadingCompanies}
                  value={selectedCompanyForAssociation}
                  onChange={(e, newValue) => {
                    setSelectedCompanyForAssociation(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Company"
                      fullWidth
                      placeholder="Start typing to search for a company"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!updateSuccess && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateCompany}
              disabled={!selectedCompanyForAssociation || updatingCompany}
              startIcon={
                updatingCompany ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {updatingCompany ? 'Updating...' : 'Link Company'}
            </Button>
          )}
          <Button onClick={handleCloseAssociationDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default QuoteGeneratorView;
