import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  Paper,
  List,
  ListItem,
  IconButton,
  LinearProgress,
  CircularProgress,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import LaunchIcon from '@material-ui/icons/Launch';
import { fetchDriveFolders, createDriveFolder } from '../../lib/Api/Drive';
import { DriveFolder } from '../../lib/Model/Drive';
import { useDebounce } from 'hooks';

const DRIVE_FOLDER_BASE_URL = 'https://drive.google.com/drive/folders/';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  alertMessage: {
    marginTop: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  mainContainer: {
    padding: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  infoIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  checkboxContainer: {
    marginBottom: theme.spacing(1),
  },
  suggestionsBox: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  suggestionItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  suggestionText: {
    marginRight: theme.spacing(2),
  },
  externalLinkIcon: {
    fontSize: '1rem',
  },
  folderSuggestionContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  radioGroup: {
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  registrationTypeControl: {
    marginBottom: theme.spacing(2),
    minWidth: '100%',
  },
  prefixSuffixControl: {
    minWidth: 120,
  },
  fieldGroup: {
    marginBottom: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5),
  },
}));

const MALTA_REGISTRATION_TYPES = [
  'Registration Number',
  'VAT Number',
  'ID',
  'PE Number',
  'Other',
];

const REGISTRATION_PREFIX_OPTIONS = [
  'C',
  'P',
  'OC',
  'SV',
  'LPF',
  'VO',
  'PCOMM',
  'LPA',
];

const ID_SUFFIX_OPTIONS = ['M', 'G', 'A'];

export const DriveFolderView: React.FC = () => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState('');
  const [folderSearchTerm, setFolderSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(folderSearchTerm, 400);
  const [regNumber, setRegNumber] = useState('');

  const [entityType, setEntityType] = useState('Malta');
  const [registrationType, setRegistrationType] = useState(
    MALTA_REGISTRATION_TYPES[0],
  );
  const [prefix, setPrefix] = useState('C');
  const [suffix, setSuffix] = useState(''); // Initialize with empty string instead of 'MT'
  const [customSuffix, setCustomSuffix] = useState('');
  const [validationError, setValidationError] = useState('');

  const [folderSuggestions, setFolderSuggestions] = useState<DriveFolder[]>([]);
  const [loadingFolders, setLoadingFolders] = useState(false);

  const [creatingFolder, setCreatingFolder] = useState(false);
  const [folderCreatedUrl, setFolderCreatedUrl] = useState<string | null>(null);
  const [folderError, setFolderError] = useState<string | null>(null);

  const fetchFolderSuggestions = useCallback(async () => {
    if (debouncedSearchTerm.trim().length > 3) {
      setLoadingFolders(true);
      try {
        const response = await fetchDriveFolders(debouncedSearchTerm);
        setFolderSuggestions(response.data);
      } catch (error) {
        setFolderSuggestions([]);
      } finally {
        setLoadingFolders(false);
      }
    } else {
      setFolderSuggestions([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchFolderSuggestions();
  }, [debouncedSearchTerm, fetchFolderSuggestions]);

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setCompanyName(value);
    setFolderSearchTerm(value);
  };

  const handleRegNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (
      entityType === 'Malta' &&
      registrationType !== 'Other' &&
      !/^\d*$/.test(value)
    ) {
      setValidationError('Only numbers are allowed in this field');
      return;
    }
    setValidationError('');
    setRegNumber(value);
    setFolderSearchTerm(value);
  };

  const handleEntityTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setEntityType(value);
    if (value === 'Malta') {
      setRegistrationType(MALTA_REGISTRATION_TYPES[0]);
      setPrefix('C');
    } else {
      setRegistrationType('');
      setPrefix('');
      setSuffix('');
    }
  };

  const handleRegistrationTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string;
    setRegistrationType(value);

    switch (value) {
      case 'Registration Number':
        setPrefix('C');
        setSuffix(''); // Make sure suffix is empty for Registration Number
        break;
      case 'VAT Number':
        setPrefix('');
        setSuffix('MT');
        break;
      case 'ID':
        setPrefix('');
        setSuffix('M');
        break;
      case 'PE Number':
        setPrefix('PE');
        setSuffix('');
        break;
      case 'Other':
        setPrefix('');
        setSuffix('');
        break;
      default:
        setPrefix('');
        setSuffix('');
    }
  };

  const handlePrefixChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPrefix(event.target.value as string);
  };

  const handleSuffixChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSuffix(event.target.value as string);
  };

  const handleCustomSuffixChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomSuffix(event.target.value);
  };

  const handleOpenFolder = (folderId: string) => {
    window.open(DRIVE_FOLDER_BASE_URL + folderId, '_blank');
  };

  const handleCreateFolder = async () => {
    if (!companyName.trim()) {
      setFolderError('Company name is required');
      return;
    }

    if (
      entityType === 'Malta' &&
      registrationType !== 'Other' &&
      !regNumber.trim()
    ) {
      setFolderError('Registration number is required');
      return;
    }

    if (!regNumber.trim()) {
      setFolderError('Registration number is required');
      return;
    }

    setFolderError(null);
    setFolderCreatedUrl(null);
    setCreatingFolder(true);

    let formattedRegNumber = regNumber.trim();

    if (prefix) {
      formattedRegNumber = prefix + formattedRegNumber;
    }

    if (suffix) {
      if (suffix === 'Custom' && customSuffix) {
        formattedRegNumber = formattedRegNumber + customSuffix;
      } else if (suffix !== 'Custom') {
        formattedRegNumber = formattedRegNumber + suffix;
      }
    }

    try {
      const response = await createDriveFolder(
        companyName.trim(),
        formattedRegNumber,
      );
      setFolderCreatedUrl(response.data.company_folder_url);
    } catch (error) {
      setFolderError('Failed to create folder. Please try again.');
    } finally {
      setCreatingFolder(false);
    }
  };

  const handleClear = () => {
    setCompanyName('');
    setFolderSearchTerm('');
    setRegNumber('');
    setFolderSuggestions([]);
    setFolderCreatedUrl(null);
    setFolderError(null);
    setEntityType('Malta');
    setRegistrationType(MALTA_REGISTRATION_TYPES[0]);
    setPrefix('C');
    setSuffix('MT');
    setCustomSuffix('');
    setValidationError('');
  };

  const renderRegistrationField = () => {
    if (entityType === 'Malta') {
      switch (registrationType) {
        case 'Registration Number':
          return (
            <Grid container spacing={2} className={classes.fieldGroup}>
              <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="prefix-label">Prefix</InputLabel>
                  <Select
                    labelId="prefix-label"
                    value={prefix}
                    onChange={handlePrefixChange}
                    label="Prefix"
                  >
                    {REGISTRATION_PREFIX_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  label="Registration Number"
                  value={regNumber}
                  onChange={handleRegNumberChange}
                  fullWidth
                  placeholder="Enter the registration number (numbers only)"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!validationError}
                  helperText={validationError || ''}
                />
              </Grid>
            </Grid>
          );

        case 'VAT Number':
          return (
            <Grid container spacing={2} className={classes.fieldGroup}>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  label="VAT Number"
                  value={regNumber}
                  onChange={handleRegNumberChange}
                  fullWidth
                  placeholder="Enter the VAT number (numbers only)"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!validationError}
                  helperText={validationError || ''}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Suffix"
                  value={suffix}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setSuffix(e.target.value)}
                  defaultValue="MT"
                  fullWidth
                />
              </Grid>
            </Grid>
          );

        case 'ID':
          return (
            <Grid container spacing={2} className={classes.fieldGroup}>
              <Grid item xs={suffix === 'Custom' ? 8 : 10}>
                <TextField
                  variant="outlined"
                  label="ID Number"
                  value={regNumber}
                  onChange={handleRegNumberChange}
                  fullWidth
                  placeholder="Enter the ID number (numbers only)"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!validationError}
                  helperText={validationError || ''}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="id-suffix-label">Suffix</InputLabel>
                  <Select
                    labelId="id-suffix-label"
                    value={suffix}
                    onChange={handleSuffixChange}
                    label="Suffix"
                  >
                    {ID_SUFFIX_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    <MenuItem value="Custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {suffix === 'Custom' && (
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="Custom Suffix"
                    value={customSuffix}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleCustomSuffixChange}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          );

        case 'PE Number':
          return (
            <Grid container spacing={2} className={classes.fieldGroup}>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Prefix"
                  value={prefix}
                  disabled={true}
                  defaultValue="PE"
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  label="PE Number"
                  value={regNumber}
                  onChange={handleRegNumberChange}
                  fullWidth
                  placeholder="Enter the PE number (numbers only)"
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!validationError}
                  helperText={validationError || ''}
                />
              </Grid>
            </Grid>
          );

        case 'Other':
          return (
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                variant="outlined"
                label="Other Identifier"
                value={regNumber}
                onChange={handleRegNumberChange}
                fullWidth
                placeholder="Enter the identifier"
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
          );

        default:
          return null;
      }
    } else {
      return (
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            variant="outlined"
            label="Registration Number"
            value={regNumber}
            onChange={handleRegNumberChange}
            fullWidth
            placeholder="Enter the registration number, or any other identifier for this company."
            InputLabelProps={{ shrink: true }}
            required
          />
        </FormControl>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Create Drive Folder"
            subheader="Create client folders in Google Drive"
          />
          <Divider />

          <CardContent className={classes.mainContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    variant="outlined"
                    label="Company Name"
                    placeholder="Enter the company name"
                    fullWidth
                    autoFocus
                    required
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>

                {folderSuggestions.length > 0 && (
                  <Paper className={classes.suggestionsBox} variant="outlined">
                    <Typography variant="subtitle2" gutterBottom>
                      Did you mean one of these existing folders?
                    </Typography>
                    <List dense>
                      {folderSuggestions.map((folder) => (
                        <ListItem
                          key={folder.id}
                          className={classes.suggestionItem}
                        >
                          <div className={classes.folderSuggestionContent}>
                            <Typography className={classes.suggestionText}>
                              {folder.folder_name}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => handleOpenFolder(folder.id)}
                            >
                              <LaunchIcon
                                className={classes.externalLinkIcon}
                              />
                            </IconButton>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                    {loadingFolders}
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Entity Type</FormLabel>
                  <RadioGroup
                    value={entityType}
                    onChange={handleEntityTypeChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="Malta"
                      control={<Radio />}
                      label="Malta"
                    />
                    <FormControlLabel
                      value="Foreign"
                      control={<Radio />}
                      label="Foreign"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {entityType === 'Malta' && (
                <Grid item xs={12}>
                  <FormControl
                    className={classes.registrationTypeControl}
                    variant="outlined"
                  >
                    <InputLabel>Registration Type</InputLabel>
                    <Select
                      value={registrationType}
                      onChange={handleRegistrationTypeChange}
                      label="Registration Type"
                    >
                      {MALTA_REGISTRATION_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                {renderRegistrationField()}
              </Grid>

              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateFolder}
                  className={classes.actionButton}
                  startIcon={
                    creatingFolder ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CreateNewFolderIcon />
                    )
                  }
                  disabled={
                    creatingFolder || !companyName.trim() || !!validationError
                  }
                >
                  {creatingFolder ? 'Creating...' : 'Create Folder'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClear}
                  disabled={creatingFolder}
                >
                  Clear
                </Button>
                {creatingFolder && (
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ marginTop: 8, color: '#666' }}
                  >
                    Folder creation may take up to a minute to complete.
                  </Typography>
                )}
              </Grid>

              {creatingFolder && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              {folderError && (
                <Grid item xs={12}>
                  <Alert severity="error">{folderError}</Alert>
                </Grid>
              )}

              {folderCreatedUrl && (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        href={folderCreatedUrl}
                        target="_blank"
                        startIcon={<LaunchIcon />}
                      >
                        Open
                      </Button>
                    }
                  >
                    Folder created successfully!
                  </Alert>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
