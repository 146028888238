import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormLabel,
  Box,
  Link,
  Tooltip,
  IconButton,
  Chip,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import LaunchIcon from '@material-ui/icons/Launch';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fetchDriveCompanies } from 'lib/Api/Drive';
import { DriveCompany } from 'lib/Model/Drive';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Warning } from '@material-ui/icons';
import TermsGenerationDialog from './TermsGenerationDialog';
import CompanyAssociationDialog from './CompanyAssociationDialog';
import { PageableTable } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import { useLocation } from 'react-router-dom';
import { fetchTerms } from 'lib/Api/TermsGenerator';
import { FetchTermsResponse, TermsDocument } from 'lib/Model/TermsGenerator';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  alertMessage: {
    marginTop: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  mainContainer: {
    padding: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  searchBox: {
    padding: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  infoIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  checkboxContainer: {
    marginBottom: theme.spacing(1),
  },
  suggestionsBox: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  suggestionItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  suggestionText: {
    marginRight: theme.spacing(2),
  },
  externalLinkIcon: {
    fontSize: '1rem',
  },
  folderSuggestionContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  radioGroup: {
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  registrationTypeControl: {
    marginBottom: theme.spacing(2),
    minWidth: '100%',
  },
  fieldGroup: {
    marginBottom: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5),
  },
  folderFoundMessage: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.success.main,
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
  folderNotFoundMessage: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
  workingFolderNotFoundMessage: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.dark,
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
  expiryDateControl: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  yearsInput: {
    width: 100,
  },
  datePicker: {
    flexGrow: 1,
  },
  indefiniteSwitch: {
    marginRight: theme.spacing(1),
  },
  dateRow: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  dateField: {
    flex: 1,
  },
  indefiniteContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    paddingBottom: theme.spacing(1),
  },
  statusActive: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    fontWeight: 'bold',
  },
  statusExpired: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    fontWeight: 'bold',
  },
  statusPending: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    fontWeight: 'bold',
  },
  statusChip: {
    minWidth: '90px',
  },
}));

export const TermsGeneratorView: React.FC = () => {
  const classes = useStyles();
  const today = new Date();

  const defaultExpiryDate = new Date(today);
  defaultExpiryDate.setFullYear(defaultExpiryDate.getFullYear() + 1);

  const [brand, setBrand] = useState<'SIM' | 'Konnekt'>('SIM');
  const [termsType, setTermsType] = useState<'Standard' | 'Exclusive'>(
    'Standard',
  );
  const [companyName, setCompanyName] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [registrationType, setRegistrationType] = useState<
    'registration' | 'vat' | 'other'
  >('registration');
  const [address, setAddress] = useState('');
  const [signatory, setSignatory] = useState('');
  const [dateOfIssue, setDateOfIssue] = useState<Date>(today);

  const [expiryYears, setExpiryYears] = useState<number>(1);
  const [expiryDate, setExpiryDate] = useState<Date | null>(defaultExpiryDate);
  const [isIndefinite, setIsIndefinite] = useState<boolean>(false);

  const [folderDestination] = useState<'working'>('working');

  const [selectedCompanyFromDropdown, setSelectedCompanyFromDropdown] =
    useState<DriveCompany | null>(null);
  const [useExistingCompany, setUseExistingCompany] = useState(true);
  const [companies, setCompanies] = useState<DriveCompany[] | undefined>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const [fileCreatedUrl, setFileCreatedUrl] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);
  const [selectedTermsForAssociation, setSelectedTermsForAssociation] =
    useState<{
      id: number;
      companyName: string;
    } | null>(null);

  const [termsHistory, setTermsHistory] = useState<
    FetchTermsResponse | undefined
  >();
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [historyTextSearch, setHistoryTextSearch] = useState<
    string | undefined
  >();
  const debouncedHistoryTextSearch = useDebounce(historyTextSearch, 400);
  const [historyPageNumber, setHistoryPageNumber] = useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const historyColumns: PageableTableHeader[] = [
    {
      key: 'effective_date',
      label: 'Effective Date',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'brand',
      label: 'Brand',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'company_name',
      label: 'Company Name',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'expiry_date',
      label: 'Expiry Date',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'date',
      label: 'Created On',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'created_by',
      label: 'Created By',
      sortable: false,
      props: { align: 'center' },
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      props: { align: 'center' },
    },
  ];

  const fetchCompanies = useCallback(() => {
    setLoadingCompanies(true);
    fetchDriveCompanies().then((d) => {
      setCompanies(d.data);
      setLoadingCompanies(false);
    });
  }, []);

  useEffect(() => {
    if (!loadingCompanies && companies === undefined) {
      fetchCompanies();
    }
  }, [fetchCompanies, loadingCompanies, companies]);

  useEffect(() => {
    if (selectedCompanyFromDropdown) {
      setCompanyName(selectedCompanyFromDropdown.company_title);
      updateRegistrationNumberByType(
        selectedCompanyFromDropdown,
        registrationType,
      );
    }
  }, [selectedCompanyFromDropdown, registrationType]);

  useEffect(() => {
    if (selectedCompanyFromDropdown) {
      updateRegistrationNumberByType(
        selectedCompanyFromDropdown,
        registrationType,
      );
    }
  }, [registrationType, selectedCompanyFromDropdown]);

  useEffect(() => {
    if (!isIndefinite && dateOfIssue) {
      const newExpiryDate = new Date(dateOfIssue);
      newExpiryDate.setFullYear(newExpiryDate.getFullYear() + expiryYears);
      setExpiryDate(newExpiryDate);
    }
  }, [dateOfIssue, expiryYears, isIndefinite]);

  const fetchTermsHistoryData = useCallback(() => {
    setLoadingHistory(true);
    fetchTerms({
      page: historyPageNumber + 1,
      pageSize: tableSettings.rowsPerPage,
      searchParam: debouncedHistoryTextSearch,
    })
      .then((d) => {
        setLoadingHistory(false);
        setTermsHistory(d);
      })
      .catch((error) => {
        setLoadingHistory(false);
      });
  }, [
    historyPageNumber,
    tableSettings.rowsPerPage,
    debouncedHistoryTextSearch,
  ]);

  useEffect(() => {
    fetchTermsHistoryData();
  }, [fetchTermsHistoryData]);

  const updateRegistrationNumberByType = (
    company: DriveCompany,
    type: 'registration' | 'vat' | 'other',
  ) => {
    switch (type) {
      case 'registration':
        setRegistrationNumber(company.CNumber || '');
        break;
      case 'vat':
        setRegistrationNumber(company.VAT_number || '');
        break;
      case 'other':
        setRegistrationNumber('');
        break;
    }
  };

  const handleRegistrationTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRegistrationType(event.target.value as 'registration' | 'vat' | 'other');
  };

  const handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBrand(event.target.value as 'SIM' | 'Konnekt');
  };

  const handleTermsTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setTermsType(event.target.value as 'Standard' | 'Exclusive');
  };

  const handleYearsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value > 0) {
      setExpiryYears(value);
    }
  };

  const handleIndefiniteToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsIndefinite(event.target.checked);
  };

  const handleExpiryDateChange = (date: Date | null) => {
    if (date) {
      setExpiryDate(date);
    }
  };

  const validateForm = (): boolean => {
    const errors: Record<string, string> = {};

    if (!companyName.trim()) {
      errors.companyName = 'Company name is required';
    }

    if (!registrationNumber.trim()) {
      errors.registrationNumber = 'Identification number is required';
    }

    if (!address.trim()) {
      errors.address = 'Address is required';
    }

    if (!signatory.trim()) {
      errors.signatory = 'Signatory is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateFile = async () => {
    if (!validateForm()) {
      return;
    }

    setFileError(null);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSuccess = () => {
    setValidationErrors({});
  };

  const handleClear = () => {
    setBrand('SIM');
    setTermsType('Standard');
    setCompanyName('');
    setRegistrationNumber('');
    setRegistrationType('registration');
    setAddress('');
    setSignatory('');
    setDateOfIssue(today);
    setSelectedCompanyFromDropdown(null);
    setUseExistingCompany(true);
    setFileCreatedUrl(null);
    setFileError(null);
    setValidationErrors({});
    setExpiryYears(1);
    setExpiryDate(defaultExpiryDate);
    setIsIndefinite(false);
  };

  const getFolderStatusMessage = () => {
    if (!selectedCompanyFromDropdown) return null;

    const hasWorkingFolder =
      !!selectedCompanyFromDropdown.working_agreements_folder_id;
    const hasParentFolder = !!selectedCompanyFromDropdown.parent_folder_id;

    if (!hasWorkingFolder && !hasParentFolder) {
      return (
        <Typography variant="caption" className={classes.folderNotFoundMessage}>
          <Warning fontSize="small" />
          Couldn't automatically find the Drive folder for this client. This
          folder may not exist, or the folder ID is missing on Zendesk. You'll
          need to manually pick the folder if you want to save the terms there,
          or download the terms and upload it to the correct folder later.
        </Typography>
      );
    }

    if (hasWorkingFolder) {
      return (
        <Typography variant="caption" className={classes.folderFoundMessage}>
          <CheckCircleIcon fontSize="small" />
          Drive folder found. Document will be saved in the working documents
          folder.
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="caption"
          className={classes.workingFolderNotFoundMessage}
        >
          <Warning fontSize="small" />
          Drive Folder found, but could not find working documents folder. The
          document will be saved to the main company folder. If you save this
          file in the main company folder, please remember to move it to the
          working documents folder later.
        </Typography>
      );
    }
  };

  const handleOpenAssociationDialog = (
    termsId: number,
    companyName: string,
  ) => {
    setSelectedTermsForAssociation({ id: termsId, companyName });
    setAssociationDialogOpen(true);
  };

  const handleCloseAssociationDialog = () => {
    setAssociationDialogOpen(false);
    setSelectedTermsForAssociation(null);
  };

  const handleAssociationSuccess = () => {
    fetchTermsHistoryData();
  };

  const getTermStatus = (
    createdOn: string | null,
    expiryDate: string | null,
  ) => {
    const today = new Date();
    const createdOnDate = createdOn ? new Date(createdOn) : null;
    const expiryDateTime = expiryDate ? new Date(expiryDate) : null;

    if (createdOnDate && createdOnDate > today) {
      return {
        label: 'Not in effect',
        icon: <HourglassEmptyIcon fontSize="small" />,
        className: classes.statusPending,
      };
    }

    if (!expiryDateTime || expiryDateTime > today) {
      return {
        label: 'Active',
        icon: <CheckCircleIcon fontSize="small" />,
        className: classes.statusActive,
      };
    }

    // if expiry date is in the past
    return {
      label: 'Expired',
      icon: <CancelIcon fontSize="small" />,
      className: classes.statusExpired,
    };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Create Terms of Business"
            subheader="Generate Terms of Business documents for clients"
          />
          <Divider />

          <CardContent className={classes.mainContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="brand-label">Brand</InputLabel>
                  <Select
                    labelId="brand-label"
                    value={brand}
                    onChange={handleBrandChange}
                    label="Brand"
                  >
                    <MenuItem value="SIM">SIM</MenuItem>
                    <MenuItem value="Konnekt">Konnekt</MenuItem>
                    <MenuItem value="Talexio" disabled>
                      Talexio (coming soon)
                    </MenuItem>
                    <MenuItem value="PayrollMalta" disabled>
                      Payroll Malta (coming soon)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {brand === 'Konnekt' && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="terms-type-label">Terms Type</InputLabel>
                    <Select
                      labelId="terms-type-label"
                      value={termsType}
                      onChange={handleTermsTypeChange}
                      label="Terms Type"
                    >
                      <MenuItem value="Standard">Standard</MenuItem>
                      <MenuItem value="Exclusive">Exclusive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useExistingCompany}
                      onChange={(e) => {
                        setUseExistingCompany(e.target.checked);
                        if (!e.target.checked) {
                          setSelectedCompanyFromDropdown(null);
                        }
                        setCompanyName('');
                      }}
                    />
                  }
                  label="Select from existing companies"
                />
              </Grid>
              <Grid item xs={12}>
                {useExistingCompany ? (
                  <>
                    <Autocomplete
                      getOptionLabel={(c) => c.company_title}
                      options={companies ?? []}
                      loading={loadingCompanies}
                      value={selectedCompanyFromDropdown}
                      onChange={(e, newValue) => {
                        setSelectedCompanyFromDropdown(newValue);
                        if (newValue) {
                          setCompanyName(newValue.company_title);
                        } else {
                          setCompanyName('');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Company Name"
                          fullWidth
                          placeholder="Start typing to search for a company"
                          InputLabelProps={{ shrink: true }}
                          error={!!validationErrors.companyName}
                          helperText={validationErrors.companyName}
                        />
                      )}
                    />
                    {selectedCompanyFromDropdown && getFolderStatusMessage()}
                  </>
                ) : (
                  <TextField
                    fullWidth
                    label="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!validationErrors.companyName}
                    helperText={validationErrors.companyName}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.registrationTypeControl}
                >
                  <FormLabel component="legend">Identification Type</FormLabel>
                  <RadioGroup
                    aria-label="registration-type"
                    name="registration-type"
                    value={registrationType}
                    onChange={handleRegistrationTypeChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="registration"
                      control={<Radio />}
                      label="Registration Number"
                    />
                    <FormControlLabel
                      value="vat"
                      control={<Radio />}
                      label="VAT Number"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  error={!!validationErrors.registrationNumber}
                >
                  <TextField
                    variant="outlined"
                    label={
                      registrationType === 'registration'
                        ? 'Registration Number'
                        : registrationType === 'vat'
                        ? 'VAT Number'
                        : 'Other Identifier'
                    }
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    fullWidth
                    placeholder={`Enter the ${
                      registrationType === 'registration'
                        ? 'company registration number'
                        : registrationType === 'vat'
                        ? 'VAT number'
                        : 'identifier'
                    }`}
                    InputLabelProps={{ shrink: true }}
                    error={!!validationErrors.registrationNumber}
                    helperText={validationErrors.registrationNumber}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  error={!!validationErrors.address}
                >
                  <TextField
                    variant="outlined"
                    label="Company Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Enter the company address"
                    InputLabelProps={{ shrink: true }}
                    error={!!validationErrors.address}
                    helperText={validationErrors.address}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  error={!!validationErrors.signatory}
                >
                  <TextField
                    variant="outlined"
                    label="Signatory"
                    value={signatory}
                    onChange={(e) => setSignatory(e.target.value)}
                    fullWidth
                    placeholder="Enter the name of the signatory"
                    InputLabelProps={{ shrink: true }}
                    error={!!validationErrors.signatory}
                    helperText={validationErrors.signatory}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.dateRow}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Date of Issue"
                          value={dateOfIssue}
                          onChange={(date) => {
                            if (date) {
                              setDateOfIssue(date);
                              if (!isIndefinite) {
                                const newExpiryDate = new Date(date);
                                newExpiryDate.setFullYear(
                                  newExpiryDate.getFullYear() + expiryYears,
                                );
                                setExpiryDate(newExpiryDate);
                              }
                            }
                          }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Terms Duration (years)"
                        type="number"
                        variant="outlined"
                        value={expiryYears}
                        onChange={handleYearsChange}
                        inputProps={{ min: 1 }}
                        disabled={isIndefinite}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Expiry Date"
                          value={expiryDate}
                          onChange={handleExpiryDateChange}
                          fullWidth
                          minDate={new Date(dateOfIssue)}
                          disabled={isIndefinite}
                          InputLabelProps={{ shrink: true }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Box className={classes.indefiniteContainer}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isIndefinite}
                              onChange={handleIndefiniteToggle}
                              color="primary"
                            />
                          }
                          label="Indefinite"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateFile}
                  className={classes.actionButton}
                  startIcon={<DescriptionIcon />}
                >
                  Create Terms of Business
                </Button>
                <Button variant="outlined" onClick={handleClear}>
                  Clear
                </Button>
              </Grid>

              {fileError && (
                <Grid item xs={12}>
                  <Alert severity="error">{fileError}</Alert>
                </Grid>
              )}

              {fileCreatedUrl && (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        href={fileCreatedUrl}
                        target="_blank"
                        startIcon={<LaunchIcon />}
                      >
                        Open
                      </Button>
                    }
                  >
                    Terms of Business document created successfully!
                  </Alert>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Terms of Business History"
            subheader="A history of all terms of business generated through the system."
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="Type here to search for a company."
                value={historyTextSearch}
                className={classes.searchBox}
                onChange={(e) => setHistoryTextSearch(e.target.value ?? '')}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={false}
            rows={
              termsHistory?.data?.map((term: TermsDocument, index: number) => {
                const status = getTermStatus(
                  term.date_of_issue ?? null,
                  term.terms_expiry_date ?? null,
                );
                return {
                  key: index.toString(),
                  cells: [
                    {
                      key: 'date',
                      display: term.date_of_issue && (
                        <DateDisplay date={term.date_of_issue} />
                      ),
                      props: { align: 'center' },
                    },
                    {
                      key: 'brand',
                      display: term.terms_brand,
                      props: { align: 'center' },
                    },
                    {
                      key: 'company_name',
                      display: term.company_id ? (
                        <Link
                          href={`${window.location.origin}/clients/${term.company_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="primary"
                        >
                          {term.company_name}
                        </Link>
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                        >
                          {term.company_name}
                          <Tooltip title="These terms are not properly linked with a company. Link them to make terms visible on Product Stack.">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleOpenAssociationDialog(
                                  term.id,
                                  term.company_name,
                                )
                              }
                            >
                              <LinkOffIcon color="error" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                      props: { align: 'center' },
                    },
                    {
                      key: 'expiry_date',
                      display: term.terms_expiry_date ? (
                        <DateDisplay date={term.terms_expiry_date} />
                      ) : (
                        'Indefinite'
                      ),
                      props: { align: 'center' },
                    },
                    {
                      key: 'created_on',
                      display: <DateDisplay date={term.created_on} />,
                      props: { align: 'center' },
                    },
                    {
                      key: 'created_by',
                      display: term.created_by,
                      props: { align: 'center' },
                    },
                    {
                      key: 'effect',
                      display: (
                        <Chip
                          icon={status.icon}
                          label={status.label}
                          className={`${status.className} ${classes.statusChip}`}
                          size="small"
                        />
                      ),
                      props: { align: 'center' },
                    },
                  ],
                };
              }) ?? []
            }
            loading={loadingHistory}
            pageNumber={historyPageNumber}
            rowCount={termsHistory?.total_count ?? 0}
            columns={historyColumns}
            onChangePage={setHistoryPageNumber}
          />
        </Card>
      </Grid>

      <TermsGenerationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSuccess={handleDialogSuccess}
        company={selectedCompanyFromDropdown}
        companyName={companyName}
        registrationNumber={registrationNumber}
        companyId={selectedCompanyFromDropdown?.id ?? ''}
        registrationType={registrationType}
        address={address}
        signatory={signatory}
        dateOfIssue={dateOfIssue}
        expiryDate={isIndefinite ? null : expiryDate}
        brand={brand}
        termsType={termsType}
        folderDestination={folderDestination}
      />

      {/* Add the company association dialog */}
      {selectedTermsForAssociation && (
        <CompanyAssociationDialog
          open={associationDialogOpen}
          onClose={handleCloseAssociationDialog}
          onSuccess={handleAssociationSuccess}
          termsId={selectedTermsForAssociation.id}
          termsCompanyName={selectedTermsForAssociation.companyName}
        />
      )}
    </Grid>
  );
};

export default TermsGeneratorView;
