import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Box,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { QuoteDetailItem } from 'lib/Model/QuoteGenerator';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert, Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { AllocationCompany } from 'lib/Model/ClientAllocation';
import { updateQuoteCompany } from 'lib/Api/QuoteGenerator';
import QuoteGenerationDialog from './QuoteGenerationDialog';
import LaunchIcon from '@material-ui/icons/Launch';
import { getUserData } from 'lib/Helper/Auth';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  positiveAmount: {
    color: 'green',
  },
  negativeAmount: {
    color: 'red',
  },
  companyUpdateSection: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  companySelectContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  companySelect: {
    flexGrow: 1,
  },
  updateButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  generateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    marginRight: theme.spacing(2),
  },
  slideLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1),
      fontSize: '1rem',
    },
  },
}));

interface QuoteDetailsDialogProps {
  open: boolean;
  quoteDetails: QuoteDetailItem[];
  loading: boolean;
  error: string | null;
  onClose: () => void;
  quoteId: number;
  companyId: string | null;
  companies?: AllocationCompany[];
  loadingCompanies?: boolean;
  onUpdateSuccess?: () => void;
  quoteSlideId?: string;
}

const QuoteDetailsDialog: React.FC<QuoteDetailsDialogProps> = ({
  open,
  quoteDetails,
  loading,
  error,
  onClose,
  quoteId,
  companyId,
  companies,
  loadingCompanies = false,
  onUpdateSuccess,
  quoteSlideId,
}) => {
  const classes = useStyles();
  const [selectedCompany, setSelectedCompany] =
    useState<AllocationCompany | null>(null);
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showSlidesDialog, setShowSlidesDialog] = useState(false);

  const user = getUserData();
  const permissions = user?.permissions ?? [];

  const formatNumber = (num?: number): string => {
    if (num === undefined || num === null) return '-';
    return `€${num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const getModuleName = (key: string): string => {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleUpdateCompany = async () => {
    if (!selectedCompany) return;

    setUpdating(true);
    setUpdateError(null);
    setUpdateSuccess(false);

    try {
      await updateQuoteCompany(
        quoteId,
        selectedCompany.id,
        selectedCompany.company_title,
      );
      setUpdateSuccess(true);
      if (onUpdateSuccess) {
        onUpdateSuccess();
      }
    } catch (error) {
      setUpdateError(
        `Failed to update proposal: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      );
    } finally {
      setUpdating(false);
    }
  };

  const handleGenerateProposal = () => {
    setShowSlidesDialog(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="quote-details-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="quote-details-dialog-title">
        Proposal Details (ID: {quoteId})
      </DialogTitle>
      <DialogContent dividers>
        {(!companyId || companyId === '') && (
          <Box className={classes.companyUpdateSection}>
            {updateSuccess ? (
              <Alert severity="success" variant="filled">
                Company updated successfully!
              </Alert>
            ) : (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>
                    This proposal is not properly linked to a company.
                  </strong>{' '}
                  You can update it below. The company must be created on
                  Zendesk.
                </Typography>
                {updateError && (
                  <Alert severity="error" style={{ marginBottom: '16px' }}>
                    {updateError}
                  </Alert>
                )}
                <Box className={classes.companySelectContainer}>
                  <Box className={classes.companySelect}>
                    <Autocomplete
                      getOptionLabel={(c) => c.company_title}
                      options={companies ?? []}
                      loading={loadingCompanies}
                      value={selectedCompany}
                      onChange={(e, newValue) => {
                        setSelectedCompany(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Company"
                          fullWidth
                          placeholder="Start typing to search for a company"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    className={`${classes.saveButton} ${classes.updateButton}`}
                    onClick={handleUpdateCompany}
                    disabled={!selectedCompany || updating}
                    startIcon={
                      updating ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {updating ? 'Updating...' : 'Update Company'}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}

        {loading ? (
          <Skeleton variant="rect" width="100%" height={200} />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : quoteDetails.length > 0 ? (
          <Table
            className={classes.table}
            aria-label="quote details table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Item Type</TableCell>
                <TableCell>Module</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Monthly Price</TableCell>
                <TableCell align="center">Original Monthly</TableCell>
                <TableCell align="center">Discount Monthly</TableCell>
                <TableCell align="center">One Time Price</TableCell>
                <TableCell align="center">Original One Time</TableCell>
                <TableCell align="center">Discount One Time</TableCell>
                <TableCell align="center">Setup Fee</TableCell>
                <TableCell align="center">Original Setup</TableCell>
                <TableCell align="center">Discount Setup</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quoteDetails.map((item, index) => (
                <TableRow key={`detail-${index}`}>
                  <TableCell>{item.item_type}</TableCell>
                  <TableCell>{getModuleName(item.module_key)}</TableCell>
                  <TableCell align="center">{item.user_qty}</TableCell>
                  <TableCell align="center">
                    {formatNumber(item.monthly_unit_price)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(item.original_monthly_unit_price)}
                  </TableCell>
                  <TableCell align="center" className={classes.negativeAmount}>
                    {item.discount_monthly_unit_price
                      ? `-${formatNumber(
                          item.discount_monthly_unit_price,
                        ).substring(1)}`
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(item.one_time_unit_price)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(item.original_one_time_unit_price)}
                  </TableCell>
                  <TableCell align="center" className={classes.negativeAmount}>
                    {item.discount_one_time_unit_price
                      ? `-${formatNumber(
                          item.discount_one_time_unit_price,
                        ).substring(1)}`
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(item.setup_fee)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(item.original_setup_fee)}
                  </TableCell>
                  <TableCell align="center" className={classes.negativeAmount}>
                    {item.discount_setup_fee
                      ? `-${formatNumber(item.discount_setup_fee).substring(1)}`
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No proposal details available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        {quoteSlideId ? (
          <Link
            href={`https://docs.google.com/presentation/d/${quoteSlideId}`}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            className={classes.slideLink}
          >
            View Existing Proposal in Drive
            <LaunchIcon />
          </Link>
        ) : permissions.includes('generate_quote') ? (
          <Button
            onClick={handleGenerateProposal}
            className={classes.generateButton}
          >
            Generate Proposal
          </Button>
        ) : null}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>

      <QuoteGenerationDialog
        open={showSlidesDialog}
        quoteId={quoteId}
        companyName={quoteDetails.length > 0 ? 'this company' : ''}
        onClose={() => setShowSlidesDialog(false)}
      />
    </Dialog>
  );
};

export default QuoteDetailsDialog;
