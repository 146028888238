import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { DriveCompany, DriveFolder } from '../Model/Drive';

export function fetchDriveFolders(
  searchParam?: string,
): Promise<{ data: DriveFolder[] }> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/drive/folders`, {
      params: {
        search_param: searchParam?.length ? searchParam : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        data: response.data.data as DriveFolder[],
      };
    });
}

export function createDriveFolder(
  companyName: string,
  regNumber: string,
): Promise<{ data: { company_folder_url: string } }> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/drive/folders/create`,
      {
        company_name: companyName,
        reg_number: regNumber,
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return {
        data: response.data.data,
      };
    });
}

export function fetchDriveCompanies(
  textSearch?: string,
): Promise<{ data: DriveCompany[] }> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/drive/companies`, {
      params: {
        company_title: textSearch?.length ? textSearch : undefined,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        data: response.data.data as DriveCompany[],
      };
    });
}
